import "draft-js/dist/Draft.css";
import React from "react";
import { Route, Switch, useLocation } from "react-router";
import HomePage from "./views/HomePage";
import LibraryPage from "./views/LibraryPage";
import SignInPage from "./views/SignInPage";
import SignInWithEmailPage from "./views/SignInWithEmailPage";
import SignUpPage from "./views/SignUpPage";
import ForgotPasswordPage from "./views/ForgotPasswordPage";
import EditModePage from "./views/EditModePage";
import ShareLink from "./views/ShareLinkPage";
import ShareCanvas from "./views/ShareCanvas";
import ResetPasswordPage from "./views/ResetPasswordPage";
import AccountSettingsPage from "./views/AccountSettingsPage";
import ChangePasswordPage from "./views/ChangePasswordPage";
import { BrowserRouter } from "react-router-dom";
import { useWatchAuth } from "../auth/authHooks";
import { useDispatch } from "react-redux";
import TermsAndConditions from "./views/TermsAndConditions";

const ModalSwitch = () => {
  const location = useLocation();

  const background = location.state && location.state.background;

  return (
    <>
      <Switch location={background || location}>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/terms" component={TermsAndConditions} />
        <Route exact path="/library" component={LibraryPage} />
        <Route exact path="/canvas/:canvasId" component={EditModePage} />
        <Route exact path="/canvas/:canvasId/sh" component={ShareCanvas} />
        <Route exact path="/__/auth/action" component={ResetPasswordPage} />
      </Switch>
      {background && (
        <Switch location={location}>
          <Route exact path="/signin" component={SignInPage} />
          <Route
            exact
            path="/signinwithemail"
            component={SignInWithEmailPage}
          />
          <Route exact path="/signup" component={SignUpPage} />
          <Route exact path="/settings" component={AccountSettingsPage} />
          <Route exact path="/change-password" component={ChangePasswordPage} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <Route exact path="/canvas/:canvasId/share" component={ShareLink} />
        </Switch>
      )}
    </>
  );
};

const App = () => {
  const dispatch = useDispatch();
  useWatchAuth(dispatch);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
    }}>
      <BrowserRouter>
        <ModalSwitch />
      </BrowserRouter>
    </div>
  )
};

export default App;
