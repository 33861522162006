import React from "react";
import PropTypes from "prop-types";
import { Button as RebassButton } from "rebass";
import Tooltip from "./Tooltip";

const commonStyles = {
  cursor: "pointer",
  border: "1px solid",
  height: ["1.25rem", "1.25rem", "1.875rem"],
  width: ["1.25rem", "1.25rem", "1.875rem"],
  borderRadius: "100%",
  letterSpacing: "-0.01em",
  ":focus": { outline: "none" },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: ["0.6rem", "0.6rem", "0.9rem"],
  padding: 0,
};

const lightBorder = {
  borderColor: "#121d3233",
};

const Button = ({ active, withLightBorder, tooltipText, ...otherProps }) => {
  const variant = active ? "secondary" : "primary";
  const styles = withLightBorder
    ? { ...commonStyles, ...lightBorder }
    : commonStyles;

  const iconButton = (
    <RebassButton variant={variant} sx={styles} {...otherProps} />
  );

  return tooltipText ? (
    <Tooltip small text={tooltipText}>
      {iconButton}
    </Tooltip>
  ) : (
    iconButton
  );
};

Button.propTypes = {
  type: PropTypes.oneOf([true, false]),
  withLightBorder: PropTypes.oneOf([true, false]),
};

Button.defaultProps = {
  active: false,
  withLightBorder: false,
};

export default Button;
