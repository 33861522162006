import React, { useState } from "react";

import Popup from "../components/Popup";
import Input from "../components/Input";
import Button from "../components/Button";
import ErrorsDisplay from "../components/ErrorsDisplay";
import { useSelector, useDispatch } from "react-redux";
import {
  emailSelector,
  displayNameSelector,
  providerDataSelector,
} from "../../auth/authSelectors";
import { updateCurrentUserDisplayName } from "../firebase/index";

import styled from "@emotion/styled";
import * as Yup from "yup";
import { useFormik } from "formik";
import { devices } from "../constants/devices";
import { useHistory, useLocation } from "react-router";
import { PROVIDERS } from "../constants/providers";
import { setDisplayName } from "../store/actions";

const Divider = styled.div({
  height: "1px",
  width: "100%",
  background: "#121D32",
  margin: "1.25rem 0",
  opacity: "0.2",
});

const MarginInput = styled(Input)({
  marginBottom: "0.625rem",
  [`${devices.laptop}`]: {
    marginBottom: "1.25rem",
  },
});

const Validation = Yup.object().shape({
  name: Yup.string()
    .min(2, "your name must have at least 2 characters.")
    .required("enter name"),
});

const AccountSettingsPage = () => {
  const [errorUpdatingName, setErrorUpdatingName] = useState(null);
  const [saveButtonText, setSaveButtonText] = useState("save");
  const mail = useSelector(emailSelector);
  const name = useSelector(displayNameSelector);
  const providerData = useSelector(providerDataSelector)?.[0]?.providerId;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const handleChangeName = async ({ name }) => {
    try {
      setSaveButtonText("saving...");
      await updateCurrentUserDisplayName(name);
      dispatch(setDisplayName(name));
      setSaveButtonText("saved");
    } catch (e) {
      setErrorUpdatingName(e.message);
      setSaveButtonText("save");
    }
  };

  const handleChangeYourPassword = () => {
    history.push({
      pathname: "/change-password",
      state: { background: location.state.background },
    });
  };

  const formik = useFormik({
    initialValues: {
      mail,
      name,
    },
    enableReinitialize: true,
    onSubmit: handleChangeName,
    validationSchema: Validation,
  });

  const errors = errorUpdatingName
    ? [...Object.values(formik.errors), errorUpdatingName]
    : Object.values(formik.errors);

  return (
    <Popup title="account settings">
      <ErrorsDisplay errors={errors} />

      <MarginInput
        placeholder={
          formik.errors.name && formik.touched.name
            ? formik.errors.name
            : "name"
        }
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name ? formik.errors.name : null}
      />
      <MarginInput
        disabled
        placeholder={
          formik.errors.mail && formik.touched.mail
            ? formik.errors.mail
            : "email"
        }
        name="mail"
        value={formik.values.mail}
        onChange={formik.handleChange}
        error={formik.touched.mail ? formik.errors.mail : null}
      />
      <Button type="large" variant="secondary" onClick={formik.handleSubmit}>
        {saveButtonText}
      </Button>
      {PROVIDERS.our === providerData && (
        <>
          <Divider />
          <Button
            onClick={handleChangeYourPassword}
            type="large"
            variant="secondary"
          >
            change your password
          </Button>
        </>
      )}
    </Popup>
  );
};

export default AccountSettingsPage;
