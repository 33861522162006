import React, { useCallback, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useLocation, useHistory } from 'react-router-dom';
import Button from '../components/Button';
import Popup from '../components/Popup';
import Input from '../components/Input';
import { authGoogle, checkIfEmailExists } from '../firebase';
import useRedirectAfterLogin from '../hooks/useRedirectAfterLogin';

const Divider = styled.div({
  height: '1px',
  width: '100%',
  background: '#121D32',
  margin: '1.25rem 0',
  opacity: '0.2',
});

const MarginButton = styled(Button)(() => ({
  width: '100%',
  marginTop: '1.25rem',
}));

const ErrorDisplay = styled.span({
  color: 'red',
  fontSize: 18,
  padding: 5,
  margin: 5,
});

const SignInPageFirst = () => {
  const [mail, setMail] = useState('');
  const [mailError, setMailError] = useState('');
  const location = useLocation();
  const history = useHistory();
  useRedirectAfterLogin();

  const handleMailChange = useCallback((e) => {
    setMail(e.target.value);
  }, [setMail]);

  // const handleSignInWithGoogle = () => {
  //   authGoogle();
  // };

  useEffect(() => {
    if (mail) setMailError('');
  }, [mail]);

  const handleContinueWithEmail = async () => {
    if (!mail) {
      setMailError('Please complete the field to sign in or sign up');
      return;
    }

    checkIfEmailExists(mail)
      .then((emailExists) => {
        if (emailExists) {
          history.push({
            pathname: '/signinwithemail',
            state: { background: location.state.background, mail },
          });
        } else {
          history.push({
            pathname: '/signup',
            state: { background: location.state.background, mail },
          });
        }
      })
      .catch((e) => {
        if (e.code === 'auth/invalid-email') {
          setMailError('Please enter a valid email');
        }
      });
  };

  return (
    <Popup>
      {/* <Button onClick={handleSignInWithGoogle} type="large" variant="secondary">continue with google</Button> */}
      {/* <Divider /> */}
      <Input placeholder="email" value={mail} onChange={handleMailChange} />
      {mailError && <ErrorDisplay>{mailError}</ErrorDisplay>}
      <MarginButton onClick={handleContinueWithEmail} type="large" variant="secondary">continue with your email</MarginButton>
    </Popup>
  );
};

export default SignInPageFirst;
