import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Button } from "rebass";
import { useHistory, useLocation } from "react-router-dom";
import { devices } from "../constants/devices";

import Text from "./Text";

const ClosePopupButton = styled(Button)(() => ({
  borderRadius: "100%",
  boxSizing: "border-box",
  border: "1px solid #121D32",
  padding: "5px 10px",
  fontWeight: "bold",
  cursor: "pointer",
  marginRight: "1.25rem",
}));

const ContainerHeader = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
});

const Container = styled.div({
  position: "fixed",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: "auto",
  padding: 40,
  opacity: 0.95,
  background: "white",
  zIndex: 99,
  overflow: "scroll",
});

const Content = styled.div({
  display: "flex",
  width: "100%",
  placeContent: "center center",
  flexDirection: "column",
  height: "100%",
  margin: "0 auto",
  [`${devices.laptop}`]: {
    width: "29rem",
  },
});

const ESCAPE_KEY = 27;

const Popup = ({ title, children, hideClose = false }) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const callbackGoBack = (e) => {
      if (e.keyCode == ESCAPE_KEY) {
        goBack();
      }
    };
    document.addEventListener("keydown", callbackGoBack);
    return () => document.removeEventListener("keydown", callbackGoBack);
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  });

  const goBack = () => history.push(location.state.background.pathname);

  const back = (e) => {
    e.stopPropagation();
    goBack();
  };

  return (
    <Container>
      <ContainerHeader>
        {!hideClose && <ClosePopupButton onClick={back}>X</ClosePopupButton>}
        {title && <Text type="title">{title}</Text>}
      </ContainerHeader>
      <Content>{children}</Content>
    </Container>
  );
};

export default Popup;
