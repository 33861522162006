import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { isAnonSelector } from '../../auth/authSelectors';

const useRedirectAfterLogin = (path = '/library') => {
  const history = useHistory();
  const isAnon = useSelector(isAnonSelector);
  useEffect(() => {
    if (!isAnon) {
      history.push(path);
    }
  }, [isAnon]);
};

export default useRedirectAfterLogin;
