import {
  CompositeDecorator,
  convertToRaw,
  convertFromRaw,
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
} from 'draft-js';
import React, {
  useCallback, useRef, useEffect, useState, useMemo,
} from 'react';
import firebase from 'firebase/app';
import equal from 'fast-deep-equal';
import styled from '@emotion/styled';
import { editorControls } from '../variables';

import Text from './components/Text';
import Tooltip from './components/Tooltip';
import { devices } from './constants/devices';

const customStyleMap = {};
Object.entries(editorControls.colors).forEach(
  ([name, color]) => (customStyleMap[name] = { color }),
);

const BoxTitle = styled(Text)(({ theme }) => ({
  marginTop: 0,
  color: theme.text,
}));

const BoxHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
});

const TooltipIcon = styled.span(({ theme }) => ({
  borderBottom: `1px solid ${theme.border}`,
  color: theme.border,
  height: 'fit-content',
  cursor: 'help',
}));

const Box = ({
  children,
  canvasId,
  theme,
  name,
  placeholder,
  height = 1,
  setFocusedEditor,
  setFocusedEditorRef,
  rowStart,
  rowEnd,
  columnStart,
  columnEnd,
}) => {
  const decorator = useMemo(
    () => new CompositeDecorator([
      {
        strategy: handleStrategy,
        component: HandleSpan,
      },
      {
        strategy: hashtagStrategy,
        component: HashtagSpan,
      },
      {
        strategy: findLinkEntities,
        component: Link,
      },
      {
        strategy: urlStrategy,
        component: Link,
      },
    ]),
  );
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(decorator),
  );
  const boxRef = useRef(null);
  const handleKeyCommand = useCallback((command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  });

  const rawState = useRef();
  const rawSelection = useRef();
  useEffect(() => {
    const ref = firebase.database().ref(`/canvases/${canvasId}/${name}`);
    ref.on('value', (snapshot) => {
      const raw = snapshot.val();

      if (!raw) return;

      // bring back empty things that firebase stripped out
      if (!raw.entityMap) raw.entityMap = {};
      raw.blocks.forEach((block) => {
        if (!block.data) block.data = {};
        if (!block.entityRanges) block.entityRanges = [];
        if (!block.inlineStyleRanges) block.inlineStyleRanges = [];
      });

      if (equal(raw, rawState.current)) return;
      if (rawSelection.current) {
        setEditorState(
          EditorState.forceSelection(
            EditorState.createWithContent(convertFromRaw(raw), decorator),
            rawSelection.current,
          ),
        );
      } else {
        setEditorState(
          EditorState.createWithContent(convertFromRaw(raw), decorator),
        );
      }
    });
    return () => {
      ref.off('value');
    };
  }, [canvasId, setEditorState]);

  const handleChange = useCallback(
    (editorState) => {
      const selection = editorState.getSelection();
      setEditorState(editorState);
      rawState.current = convertToRaw(editorState.getCurrentContent());
      rawSelection.current = editorState.getSelection();
      firebase
        .database()
        .ref(`/canvases/${canvasId}/${name}`)
        .update(rawState.current);
    },
    [setEditorState, editorState, rawState.current, canvasId, name],
  );

  const mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);
      if (newEditorState !== editorState) {
        handleChange(newEditorState);
      }
      return;
    }

    return getDefaultKeyBinding(e);
  };

  const [hasFocus, setFocus] = useState();
  useEffect(() => {
    if (hasFocus) {
      setFocusedEditor({
        handleChange,
        editorState,
      });

      setFocusedEditorRef(boxRef);
    }
  }, [hasFocus, handleChange, editorState]);

  const hasTextInside = editorState.getCurrentContent().getPlainText('\u0001');

  const handleFocus = () => {
    setFocus(true);
  };

  return (
    <BoxContainer
      theme={theme}
      height={height}
      rowStart={rowStart}
      rowEnd={rowEnd}
      columnStart={columnStart}
      columnEnd={columnEnd}
      ref={boxRef}
    >
      {hasTextInside ? (
        <BoxHeader>
          <BoxTitle theme={theme} type="body">
            {children}
          </BoxTitle>
          <Tooltip text={placeholder}>
            <TooltipIcon theme={theme}>?</TooltipIcon>
          </Tooltip>
        </BoxHeader>
      ) : (
        <BoxTitle theme={theme} type="body">
          {children}
        </BoxTitle>
      )}
      <Editor
        customStyleMap={customStyleMap}
        editorState={editorState}
        onChange={handleChange}
        handleKeyCommand={handleKeyCommand}
        keyBindingFn={mapKeyToEditorCommand}
        placeholder={placeholder}
        onFocus={handleFocus}
        spellCheck
      />
    </BoxContainer>
  );
};

const BoxContainer = styled.div(
  ({
    theme, rowStart, rowEnd, columnStart, columnEnd,
  }) => ({
    gridColumn: 'auto',
    gridRow: 'auto',
    padding: 20,
    border: '1px solid',
    background: theme.backgroundBox,
    borderColor: theme.border,
    borderRadius: theme.borderRadius,
    [`${devices.laptop}`]: {
      gridColumn: `${columnStart} / ${columnEnd}`,
      gridRow: `${rowStart} / ${rowEnd}`,
    },
  }),
);

const HANDLE_REGEX = /@[\w]+/g;
const HASHTAG_REGEX = /#[\w\u0590-\u05ff]+/g;
const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
function handleStrategy(contentBlock, callback, contentState) {
  findWithRegex(HANDLE_REGEX, contentBlock, callback);
}
function hashtagStrategy(contentBlock, callback, contentState) {
  findWithRegex(HASHTAG_REGEX, contentBlock, callback);
}
function urlStrategy(contentBlock, callback, contentState) {
  findWithRegex(URL_REGEX, contentBlock, callback);
}
function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null
      && contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}
function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr;
  let start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}
const HandleSpan = (props) => (
  <span
    style={{ color: 'rgba(98, 177, 254, 1.0)' }}
    data-offset-key={props.offsetKey}
  >
    {props.children}
  </span>
);
const HashtagSpan = (props) => (
  <span
    style={{ color: 'rgba(95, 184, 138, 1.0)' }}
    data-offset-key={props.offsetKey}
  >
    {props.children}
  </span>
);
const Link = (props) => {
  const { url } = props.entityKey
    ? props.contentState.getEntity(props.entityKey).getData()
    : { url: null };
  return (
    <a
      style={{ color: 'rgba(98, 177, 254, 1.0)', cursor: 'pointer' }}
      data-offset-key={props.offsetKey}
      href={url || props.decoratedText}
      target="blank"
      rel="noopener noreferer"
      onClick={() => window.open(url, '_blank')}
    >
      {props.children}
    </a>
  );
};

export default Box;
