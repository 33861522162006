import React from 'react'
import Navbar from '../components/Navbar'
import HomeMenu from '../components/HomeMenu'
import { useHistory, useLocation } from 'react-router'
import TermsOfService from '../components/TermsOfService'
import PrivacyPolicy from '../components/PrivacyPolicy'
import DMCA from '../components/DMCA'
import styled from '@emotion/styled'
import { devices } from '../constants/devices'

const Container = styled.div({
    padding: '.625rem',
    [`${devices.laptop}`]: {
        padding: '1.25rem'
    }
})

const TermsAndConditions = () => {
    const history = useHistory();
    const location = useLocation()

    const redirectSignin = {
      pathname: '/signin',
      state: { background: location },
    }
    const backToHome = () => {
        history.push('/')
    }

    return (
        <Container>
            <Navbar position="20">
            <HomeMenu
                scrollToPreviews={backToHome}
                scrollToFeatures={backToHome}
                scrollToAbout={backToHome}
                redirectSignin={redirectSignin}
            />
            </Navbar>
            <TermsOfService />
            <PrivacyPolicy />
            <DMCA />
        </Container>
    )
}

export default TermsAndConditions
