import React from 'react'
import styled from '@emotion/styled'

import TermsSectionHeader from './TermsSectionHeader'
import TermsSection from './TermsSection'
import Text from './Text'

const TextBody = styled(Text)({
  margin: '0.5rem 0 1.5rem 0 '
})

export default () => (
  <TermsSection>
    <TermsSectionHeader>
      <Text type="display">Privacy Policy</Text>
    </TermsSectionHeader>
    <Text type="title">
      Your privacy is critically important to us. At Simple Canvas App, we have a few
      fundamental principles:
    </Text>
    <ul>
      <li>
        We are thoughtful about the personal information we ask you to provide
        and the personal information that we collect about you through the
        operation of our services.
      </li>
      <li>
        We store personal information for only as long as we have a reason to
        keep it.
      </li>
      <li>
        We aim for full transparency on how we gather, use, and share your
        personal information.
      </li>
    </ul>
    <TextBody type="body">
      <a href="http://simplecanvas.app">simplecanvas.app</a> websites (collectively “Simple Canvas App” in this
      document) refer to information hosted by Simple Canvas App, and other related domains
      and subdomains thereof.
    </TextBody>
    <TextBody type="body">
      This Privacy Policy (which we very thankfully adapted from a policy
      originally provided by <a href="http://www.automattic.com/">Automattic</a>{' '}
      (<a href="http://www.wordpress.com/">WordPress.com</a>) describes how we
      treat the information we collect and display when you use Simple Canvas App. Please
      read this notice very carefully.
    </TextBody>
    <TextBody type="body">
      Below is our Privacy Policy, which incorporates and clarifies these
      principles.
    </TextBody>
    <Text type="title">Who We Are and What This Policy Covers</Text>
    <TextBody type="body">
      Hello! We are the people behind Simple Canvas App, a tool designed to allow anyone, either individually or collborating with others, to
      fill out a range of planning canvases online.
    </TextBody>
    <TextBody type="body">
      This Privacy Policy applies to information that we collect about you when
      you use:
    </TextBody>
    <ul>
      <li>
        Our website(s) found here{' '}
        <a href="simplecanvas.app/">https://simplecanvas.app/</a>
      </li>
    </ul>
    <ul>
      <li>
        Other users’ websites that use our Services, while you are logged in to
        your account with us.
      </li>
    </ul>
    <TextBody type="body">
      Throughout this Privacy Policy we’ll refer to our website, and other
      products and services collectively as “Services.”
    </TextBody>
    <TextBody type="body">
      Below we explain how we collect, use, and share information about you,
      along with the choices that you have with respect to that information.
    </TextBody>
    <Text type="title">Information We Collect</Text>
    <TextBody type="body">
      We only collect information about you if we have a reason to do so–for
      example, to provide our Services, to communicate with you, or to make our
      Services better.
    </TextBody>
    <TextBody type="body">
      We collect information in three ways: if and when you provide information
      to us, automatically through operating our Services, and from outside
      sources. Let’s go over the information that we collect.
    </TextBody>
    <TextBody type="body">
      <strong>Information You Provide to Us</strong>
    </TextBody>
    <TextBody type="body">
      It’s probably no surprise that we collect information that you provide to
      us. The amount and type of information depends on the context and how we
      use the information. Here are some examples:
    </TextBody>
    <ul>
      <li>
        <strong>Basic Account Information:</strong> We ask for basic information
        from you in order to set up your account. For example, we require
        individuals who sign up for a Simple Canvas App account to provide their name and
        email address–and that’s it.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Transaction and Billing Information:</strong> If you buy
        something from us–a subscription to a Simple Canvas App plan, for example–you will
        provide additional personal and payment information that is required to
        process the transaction and your payment, such as your name, credit card
        information, and contact information.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Content:</strong> Simple Canvas App is designed to allow anyone, either individually or collborating with others, to
      fill out a range of planning canvases online.
        To provide our Services we store, process and transmit Content as well
        as information related to it. This related information can include
        things such as your profile information and usage activity.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Communications With Us (Hi There!):</strong> You may also
        provide us information when you respond to surveys, communicate with our
        staff about a support question, or post a question about your use of
        Simple Canvas App in our public forums.
      </li>
    </ul>
    <TextBody type="body">
      <strong>Information We Collect Automatically</strong>
    </TextBody>
    <TextBody type="body">We also collect some information automatically:</TextBody>
    <ul>
      <li>
        <strong>Log Information:</strong> Like most online service providers, we
        collect information that web browsers, mobile devices, and servers
        typically make available, such as the browser type, IP address, unique
        device identifiers, language preference, referring site, the date and
        time of access, operating system, and mobile network information. We
        collect log information when you use our Services–for example, when you
        create or make changes on Simple Canvas App.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Usage Information:</strong> We collect information about your
        usage of our Services. For example, we collect information about what happens when you
        use our Services (e.g., page views, number of time entries, support document searches at,
        interactions with our Services) along with information about your device
        (e.g., screen size, name of cellular network, and mobile device
        manufacturer). We use this information to, for example, provide our
        Services to you, as well as get insights on how people use our Services,
        so we can make our Services better.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Location Information:</strong> We may determine the approximate
        location of your device from your IP address. We collect and use this
        information to, for example, calculate how many people visit our
        Services from certain geographic regions.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Stored Information:</strong> We may access information stored on
        your mobile device. We access this stored information through your
        device operating system’s permissions. For example, if you give us
        permission to access the photographs on your mobile device’s camera
        roll, our Services may access the photos stored on your device when you
        upload a photograph to a canvas.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Information from Cookies &amp; Other Technologies:</strong> A
        cookie is a string of information that a website stores on a visitor’s
        computer, and that the visitor’s browser provides to the website each
        time the visitor returns. Pixel tags (also called web beacons) are small
        blocks of code placed on websites and emails. Simple Canvas App uses cookies and
        other technologies like pixel tags to provide, improve, protect, and
        promote our Services. For example, cookies help us with things like
        remembering your username for your next visit.
      </li>
    </ul>
    <TextBody type="body">
      <strong>Information We Collect from Other Sources</strong>
    </TextBody>
    <TextBody type="body">
      We may also get information about you from other sources. For example, if
      you create or log into your Simple Canvas App account through another service (like
      Google) or if you connect your account to a social media service (like
      Twitter) we will receive information from that service (such as your
      username, basic profile information, and friends list) via the
      authorization procedures used by that service. The information we receive
      depends on which services you authorize and any options that are available
    </TextBody>
    <Text type="title">How And Why We Use Information</Text>
    <TextBody type="body">
      Purposes for Using Information <strong>-</strong> We use information about
      you as mentioned above and for the purposes listed below:
    </TextBody>
    <ul>
      <li>
        To provide our Services–for example, to set up and maintain your
        account, or charge you for any of our paid Services;
      </li>
      <li>
        To further develop and improve our Services–for example by adding new
        features that we think our users will enjoy or will help them to create,
        manage and share their canvases more efficiently;
      </li>
      <li>
        To monitor and analyze trends and better understand how users interact
        with our Services, which helps us improve our Services and make them
        easier to use;
      </li>
    </ul>
    <ul>
      <li>
        To measure, gauge, and improve the effectiveness of our advertising, and
        better understand user retention and attrition–for example, we may
        analyze how many individuals purchased a plan after receiving a
        marketing message or the features used by those who continue to use our
        Services after a certain length of time;
      </li>
    </ul>
    <ul>
      <li>
        To monitor and prevent any problems with our Services, protect the
        security of our Services, detect and prevent fraudulent transactions and
        other illegal activities, fight spam, and protect the rights and
        property of Simple Canvas App and others, which may result in us declining a
        transaction or the use of our Services;
      </li>
    </ul>
    <ul>
      <li>
        To communicate with you, for example through an email, about offers and
        promotions offered by Simple Canvas App and others we think will be of interest to
        you, solicit your feedback, or keep you up to date on our
        products; and
      </li>
    </ul>
    <ul>
      <li>
        To personalize your experience using our Services, provide
        recommendations, target our marketing messages to groups of our users
        (for example, those who have a particular plan with us or have been our
        user for a certain length of time), and serve relevant advertisements.
      </li>
    </ul>
    <TextBody type="body">
      <strong>Legal Bases for Collecting and Using Information</strong>
    </TextBody>
    <TextBody type="body">
      A note here for those in the European Union about our legal grounds for
      processing information about you under EU data protection laws, which is
      that our use of your information is based on the grounds that:
    </TextBody>
    <TextBody type="body">
      (1) The use is necessary in order to fulfill our commitments to you under
      our Terms of Service or other agreements with you or is necessary to
      administer your account–for example, in order to enable access to Simple Canvas App on
      your device or charge you for a paid plan; or
    </TextBody>
    <TextBody type="body">(2) The use is necessary for compliance with a legal obligation; or</TextBody>
    <TextBody type="body">
      (3) The use is necessary in order to protect your vital interests or those
      of another person; or
    </TextBody>
    <TextBody type="body">
      (4) We have a legitimate interest in using your information–for example,
      to provide and update our Services, to improve our Services so that we can
      offer you an even better user experience, to safeguard our Services, to
      communicate with you, to measure, gauge, and improve the effectiveness of
      our advertising, and better understand user retention and attrition, to
      monitor and prevent any problems with our Services, and to personalize
      your experience; or
    </TextBody>
    <TextBody type="body">
      (5) You have given us your consent–for example before we place certain
      cookies on your device and access and analyze them later on.
    </TextBody>
    <Text type="title">Sharing Information</Text>
    <TextBody type="body">
      <strong>How We Share Information</strong>
    </TextBody>
    <TextBody type="body">We do not sell our users’ private personal information.</TextBody>
    <TextBody type="body">
      We share information about you in the limited circumstances spelled out
      below and with appropriate safeguards on your privacy:
    </TextBody>
    <ul>
      <li>
        <strong>Subsidiaries</strong>, Employees, and Contractors: We may
        disclose information about you to our subsidiaries, our employees, and
        individuals or companies who are our contractors that need to know the
        information in order to help us provide our Services or to process the
        information on our behalf. We require our subsidiaries, employees, and
        contractors to follow this Privacy Policy for personal information that
        we share with them.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Third Party Vendors:</strong> We may share information about you
        with third party vendors who need to know information about you in order
        to provide their services to us, or to provide their services to you or
        your site. This group includes vendors that help us provide our Services
        to you (like payment providers that process your credit and debit card
        information, fraud prevention services that allow us to analyze
        fraudulent payment transactions, postal and email delivery services that
        help us stay in touch with you, customer chat and email support services
        that help us communicate with you, those that assist us with our
        marketing efforts (e.g. by providing tools for identifying a specific
        marketing target group or improving our marketing campaigns), those that
        help us understand and enhance our Services (like analytics providers),
      </li>
    </ul>
    <ul>
      <li>
        <strong>Legal Requests:</strong> We may disclose information about you
        in response to a subpoena, court order, or other governmental request.
        For more information on how we respond to requests for information about
        Simple Canvas App users.
      </li>
    </ul>
    <ul>
      <li>
        <strong>To Protect Rights, Property, and Others:</strong> We may
        disclose information about you when we believe in good faith that
        disclosure is reasonably necessary to protect the property or rights of
        Simple Canvas App, third parties, or the public at large. For example, if we have a
        good faith belief that there is an imminent danger of death or serious
        physical injury, we may disclose information related to emergency
        without delay.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Business Transfers:</strong> In connection with any merger, sale
        of company assets, or acquisition of all or a portion of our business by
        another company, or in the unlikely event that Simple Canvas App goes out of business
        or enters bankruptcy, user information would likely be one of the assets
        that is transferred or acquired by a third party. If any of these events
        were to happen, this Privacy Policy would continue to apply to your
        information and the party receiving your information may continue to use
        your information, but only consistent with this Privacy Policy.
      </li>
    </ul>
    <ul>
      <li>
        <strong>With Your Consent:</strong> We may share and disclose
        information with your consent or at your direction. For example, we may
        share your information with third parties with which you authorize us to
        do so.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Aggregated or De-Identified Information</strong>: We may share
        information that has been aggregated or reasonably de-identified, so
        that the information could not reasonably be used to identify you. For
        instance, we may publish aggregate statistics about the use of our
        Services.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Published Support Requests:</strong> And if you send us a
        request (for example, via a support email or one of our feedback
        mechanisms), we reserve the right to publish that request in order to
        help us clarify or respond to your request or to help us support other
        users.
      </li>
    </ul>
    <TextBody type="body">
      <strong>Information Shared Publicly</strong>
    </TextBody>
    <TextBody type="body">
      Information that you choose to make public is–you guessed it–disclosed
      publicly.
      <br />
      That means, of course, that information like your public profile, posts,
      other content that you make public on your boards, and your comments on
      other boards, are all potentially available to others.
    </TextBody>
    <TextBody type="body">
      For example, the photo that you upload to your public profile, or a
      default image if you haven’t uploaded one, is your Globally Recognized
      Avatar. Your Avatar, along with other public profile information, may
      display with the edits, and or comments that you make on other users’ canvases while
      logged in to your Simple Canvas App account. Your Avatar + public profile information
      may also display with your comments, and other interactions on Simple Canvas App.
    </TextBody>
    <TextBody type="body">
      Public information may also be indexed by search engines or used by third
      parties.
      <br />
      Please keep all of this in mind when deciding what you would like to
      share.
    </TextBody>
    <TextBody type="body">
      <strong>How Long We Keep Information</strong>
    </TextBody>
    <TextBody type="body">
      We generally discard information about you when we no longer need the
      information for the purposes for which we collect and use it–which are
      described in the section above on How and Why We Use Information–and we
      are not legally required to continue to keep it.
    </TextBody>
    <TextBody type="body">
      For example, we keep the web server logs that record information about a
      visitor to Simple Canvas App, such as the visitor’s IP address, browser type, and
      operating system, for approximately 30 days. We retain the logs for this
      period of time in order to, among other things, analyze traffic to Simple Canvas App
      and investigate issues if something goes wrong.
    </TextBody>
    <TextBody type="body">
      <strong>Security</strong>
    </TextBody>
    <TextBody type="body">
      While no online service is 100% secure, we work very hard to protect
      information about you against unauthorized access, use, alteration, or
      destruction, and take reasonable measures to do so, such as monitoring our
      Services for potential vulnerabilities and attacks.
    </TextBody>
    <TextBody type="body">
      <strong>Choices</strong>
    </TextBody>
    <TextBody type="body">
      You have several choices available when it comes to information about you:
    </TextBody>
    <ul>
      <li>
        <strong>Limit the Information that You Provide:</strong> If you have an
        account with us, you can choose not to provide the optional account
        information, profile information, and transaction and billing
        information. Please keep in mind that if you do not provide this
        information, certain features of our Services–for example, paid
        accounts–may not be accessible.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Limit Access to Information On Your Mobile Device:</strong> Your
        mobile device operating system should provide you with the ability to
        discontinue our ability to collect stored information or location
        information. If you do so, you may not be able to use certain features
        (like adding a location to a photograph, for example).
      </li>
    </ul>
    <ul>
      <li>
        <strong>Opt-Out of Electronic Communications:</strong> You may opt out
        of receiving promotional messages from us. Just follow the instructions
        in those messages. If you opt out of promotional messages, we may still
        send you other messages, like those about your account and legal
        notices.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Set Your Browser to Reject Cookies:</strong> At this time, Simple Canvas App
        does not respond to “do not track” signals across all of our Services.
        However, you can usually choose to set your browser to remove or reject
        browser cookies before using Simple Canvas App, with the drawback that certain
        features of Simple Canvas App may not function properly without the aid of cookies.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Close Your Account</strong>: While we’d be very sad to see you
        go, if you no longer want to use our Services :( :( :( :(, you can close
        your account/ Please keep in mind that we may continue to retain your
        information after closing your account, when that information is
        reasonably needed to comply with (or demonstrate our compliance with)
        legal obligations such as law enforcement requests, or reasonably needed
        for our legitimate business interests.
      </li>
    </ul>
    <Text type="title">Your Rights</Text>
    <TextBody type="body">
      If you are located in certain countries, including those that fall under
      the scope of the European General Data Protection Regulation (AKA the
      “GDPR”), data protection laws give you rights with respect to your
      personal data, subject to any exemptions provided by the law, including
      the rights to:
    </TextBody>
    <ul>
      <li>Request access to your personal data;</li>
      <li>Request correction or deletion of your personal data;</li>
      <li>Object to our use and processing of your personal data;</li>
      <li>
        Request that we limit our use and processing of your personal data; and
      </li>
      <li>Request portability of your personal data.</li>
    </ul>
    <TextBody type="body">
      You can usually access, correct, or delete your personal data using your
      account settings and tools that we offer, but if you aren’t able to do
      that, or you would like to contact us about one of the other rights,
      scroll down to How to Reach Us to, well, find out how to reach us.
      <br />
      EU individuals also have the right to make a complaint to a government
      supervisory authority.
    </TextBody>
    <Text type="title">How to Reach Us</Text>
    <TextBody type="body">
      If you have a question about this Privacy Policy, or you would like to
      contact us about any of the rights mentioned in the Your Rights section
      above, please{' '}
      <a href="mailto:hello@p22.studio">
        <strong>contact us</strong>
      </a>
      <strong>.</strong>
    </TextBody>
    <Text type="title">Other Things You Should Know (Keep Reading!)</Text>
    <TextBody type="body">
      <strong>Transferring Information</strong>
    </TextBody>
    <TextBody type="body">
      Because Simple Canvas App's Services are offered worldwide, the information about you
      that we process when you use the Services in the EU may be used, stored,
      and/or accessed by individuals operating outside the European Economic
      Area (EEA) who work for us, other members of our group of companies, or
      third party data processors. This is required for the purposes listed in
      the How and Why We Use Information section above. When providing
      information about you to entities outside the EEA, we will take
      appropriate measures to ensure that the recipient protects your personal
      information adequately in accordance with this Privacy Policy as required
      by applicable law. These measures include:
    </TextBody>
    <ul>
      <li>
        In the case of US based entities, entering into European Commission
        approved standard contractual arrangements with them; or
      </li>
    </ul>
    <ul>
      <li>
        In the case of entities based in other countries outside the EEA,
        entering into European Commission approved standard contractual
        arrangements with them.
      </li>
    </ul>
    <TextBody type="body">
      You can ask us for more information about the steps we take to protect
      your personal information when transferring it from the EU.
    </TextBody>
    <TextBody type="body">
      <strong>Analytics Services Provided by Others</strong>
    </TextBody>
    <TextBody type="body">
      Other parties may provide analytics services via our Services. These
      analytics providers may set tracking technologies (like cookies) to
      collect information about your use of our Services and across other
      websites and online services. These technologies allow these third parties
      to recognize your device to compile information about you or others who
      use your device. This information allows us and other companies to, among
      other things, analyze and track usage. Please note this Privacy Policy
      only covers the collection of information by Simple Canvas App and does not cover the
      collection of information by any third party analytics providers.
    </TextBody>
    <TextBody type="body">
      <strong>Privacy Policy Changes</strong>
    </TextBody>
    <TextBody type="body">
      Although most changes are likely to be minor, Simple Canvas App may change its Privacy
      Policy from time to time. Simple Canvas App encourages visitors to frequently check
      this page for any changes to its Privacy Policy.
      Your further use of the Services after a change to our Privacy Policy will
      be subject to the updated policy.
    </TextBody>
    <TextBody type="body">That’s it! Thanks for reading.</TextBody>
  </TermsSection>
)
