import React from 'react';
import Tippy from '@tippyjs/react';
import styled from '@emotion/styled';
// import 'tippy.js/dist/tippy.css'; // optional

const StyledTippy = styled(Tippy)((props) => ({
    color: 'white',
    padding: props.small ? '0.3125rem 0.625rem' : '1.25rem',
    borderRadius: '2px',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    maxWidth: '280px'
}))

const TooltipContent = styled.span({
    padding: 0,
    margin: 0,
})

const Tooltip = ({ children, text, small }) => (
    <StyledTippy interactive={true} small={small} delay={0} arrow={true} content={<TooltipContent>{text}</TooltipContent>}>
        {children}
    </StyledTippy>
);

export default Tooltip;