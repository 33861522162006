import React, { useState } from 'react'
import styled from '@emotion/styled'
import { MdBrightness1 } from 'react-icons/md'
import { editorControls } from '../../variables'

import IconButton from './IconButton'
import { devices } from '../constants/devices'

const OptionsBox = styled.div({
  border: '1px solid #E7E8EB',
  borderRadius: '2px',
  boxSizing: 'border-box',
  display: 'flex',
  position: 'absolute',
  background: 'white',
  zIndex: '99',
  bottom: 0,
  left: '-5px',
  width: '100%',
  justifyContent: 'center',
  [`${devices.laptop}`]: {
    top: '60px',
    left: '30%',
    background: 'white',
    height: '100%',
    alignItems: 'center',
    width: 'auto',
  },
})

const MarginIconButton = styled(IconButton)({
  fontSize: '0.8rem',
  margin: '0.625rem 0.4063rem',
  [`${devices.laptop}`]: {
    margin: '1.25rem 0.625rem',
    fontSize: '1rem',
  },
})

const ColorSelector = ({ toggleColor }) => {
  const [showOptions, setShowOptions] = useState(false)
  const [activeColor, setActiveColor] = useState(editorControls.colors[0])

  const toggleShowOptions = () => {
    setShowOptions((prevValue) => !prevValue)
  }

  const handleColorSelection = (newColor) => {
    setActiveColor(newColor)
    toggleShowOptions()
    toggleColor(newColor)
  }

  return (
    <>
      <IconButton
        onClick={toggleShowOptions}
        onMouseDown={(e) => {
          e.preventDefault()
        }}
      >
        <MdBrightness1 style={{ color: activeColor }} />
      </IconButton>
      {showOptions && (
        <OptionsBox>
          {Object.entries(editorControls.colors).map(([key, color]) => (
            <MarginIconButton
              onMouseDown={(e) => {
                e.preventDefault()
                handleColorSelection(key)
              }}
              withLightBorder
              key={key}
            >
              <MdBrightness1 style={{ color }} />
            </MarginIconButton>
          ))}
        </OptionsBox>
      )}
    </>
  )
}

export default ColorSelector
