import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Button from '../components/Button';
import Popup from '../components/Popup';
import Input from '../components/Input';
import { submitResetPassword } from '../firebase';
import useRedirectAfterLogin from '../hooks/useRedirectAfterLogin';
import ErrorsDisplay from '../components/ErrorsDisplay';

const Divider = styled.div({
  height: '1px',
  width: '100%',
  background: '#121D32',
  margin: '1.25rem 0',
  opacity: '0.2',
});

const MarginButton = styled(Button)(() => ({
  width: '100%',
  marginTop: '1.25rem',
}));

const Validation = Yup.object().shape({
  password: Yup.string()
    .min(4, 'Password must have at least 4 characters.')
    .required('enter password'),
});

const FIREBASE_AUTH_ACTION_RESET_PASSWORD = 'resetPassword';

const getQueryParams = (path) => {
  // we expect this shape: ?param=value&param2=value
  const cleanedPath = path.slice(1);
  const keyValues = cleanedPath.split('&');
  return keyValues.reduce((prev, curr) => {
    const keyVal = curr.split('=');
    prev[keyVal[0]] = keyVal[1];
    return prev;
  }, {});
};

const ResetPasswordPage = ({}) => {
  const [errors, setErrors] = useState([]);
  const location = useLocation();
  const history = useHistory();

  const queryParams = getQueryParams(location.search);

  const handleSubmitResetPassword = ({ password }) => {
    submitResetPassword(queryParams.oobCode, password)
      .then(() => {
        history.push('/');
      })
      .catch((e) => {
        if (e.message === 'auth/expired-action-code') {
          setErrors(['expired code']);
        } else if (e.message === 'auth/invalid-action-code') {
          setErrors(['invalid code']);
        } else if (e.message === 'auth/weak-password') {
          setErrors(['password too weak']);
        } else {
          setErrors(['Something went wrong :(']);
        }
      });
  };


  const formik = useFormik({
    initialValues: {
      password: '',
    },
    onSubmit: handleSubmitResetPassword,
    validationSchema: Validation,
  });

  return (
    <>
      {queryParams.mode !== FIREBASE_AUTH_ACTION_RESET_PASSWORD && (
        <Redirect path="/" />
      )}
      <Popup hideClose>
        <ErrorsDisplay errors={errors} />
        <Input
          type="password"
          placeholder={
            formik.errors.password && formik.touched.password
              ? formik.errors.password
              : 'new password'
          }
          name="password"
          onChange={formik.handleChange}
          error={formik.touched.password ? formik.errors.password : null}
        />
        <MarginButton
          onClick={formik.handleSubmit}
          type="large"
          variant="secondary"
        >
          change password
        </MarginButton>
      </Popup>
    </>
  );
};

export default ResetPasswordPage;
