import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { useFormik } from "formik";

import Popup from "../components/Popup";
import Input from "../components/Input";
import Button from "../components/Button";
import { authEmail } from "../firebase";
import useRedirectAfterLogin from "../hooks/useRedirectAfterLogin";
import ErrorsDisplay from "../components/ErrorsDisplay";

const Validation = Yup.object().shape({
  mail: Yup.string()
    .matches(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      "Please enter a valid email."
    )
    .required("enter email"),
  password: Yup.string()
    .min(4, "Password must have at least 4 characters.")
    .required("enter password"),
});

const SignInWithEmailPage = ({ location: { state } }) => {
  const history = useHistory();
  const location = useLocation();
  useRedirectAfterLogin();

  const [errors, setErrors] = useState([]);

  const handleSignIn = ({ mail, password }) => {
    authEmail(mail, password).catch((e) => {
      if (e.message === "auth/wrong-password") {
        setErrors(["sorry, that password didn't work"]);
      } else {
        setErrors(["oops, something went wrong :("]);
      }
    });
  };

  const handleForgotPassword = (mail) => {
    history.push({
      pathname: "/forgot-password",
      state: { background: location.state.background, mail },
    });
  };

  const formik = useFormik({
    initialValues: {
      mail: state.mail,
      password: "",
    },
    onSubmit: handleSignIn,
    validationSchema: Validation,
  });
  return (
    <Popup>
      <ErrorsDisplay errors={errors} />
      <MarginInput
        placeholder={
          formik.errors.mail && formik.touched.mail
            ? formik.errors.mail
            : "email"
        }
        name="mail"
        value={formik.values.mail}
        onChange={formik.handleChange}
        prefixIcon="arrow-left"
        prefixHandler={history.goBack}
        error={formik.touched.mail ? formik.errors.mail : null}
      />
      <Input
        placeholder={
          formik.errors.password && formik.touched.password
            ? formik.errors.password
            : "password"
        }
        name="password"
        type="password"
        onChange={formik.handleChange}
        error={formik.touched.password ? formik.errors.password : null}
        suffix="forgot?"
        suffixHandler={() => handleForgotPassword(formik.values.mail)}
      />
      <MarginButton
        type="large"
        variant="secondary"
        onClick={formik.handleSubmit}
      >
        sign in
      </MarginButton>
    </Popup>
  );
};

const MarginInput = styled(Input)(() => ({
  marginBottom: "1.25rem",
}));

const MarginButton = styled(Button)(() => ({
  marginTop: "1.25rem",
}));

SignInWithEmailPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
};

export default SignInWithEmailPage;
