import React, { useState, useEffect } from "react";
import Toolbar from "../components/Toolbar";
import { setLastViewedOnCanvas } from "../firebase";
import { useSelector } from "react-redux";
import { userIdSelector } from "../../auth/authSelectors";
import useCanvasRetrieval from "../hooks/useCanvasRetrieval";
import Canvas from "../components/Canvas";
import {
  LEAN_CANVAS_STRUCTURE,
  FEATURE_CANVAS_STRUCTURE,
  BUSINESS_MODEL_STRUCTURE,
  USER_CENTRED_DESIGN_STRUCTURE,
  MARKET_VALIDATION_STRUCTURE
} from "../constants/canvases";
import { useMediaQuery } from "@react-hook/media-query";
import { devicesHook } from "../constants/devices";
import { isIOS } from 'react-device-detect';

const getCanvasStructure = (canvasType) => {
  if (canvasType) {

    if (canvasType === 'marketValidation') {
      return MARKET_VALIDATION_STRUCTURE
    }

    if (canvasType === 'feature') {
      return FEATURE_CANVAS_STRUCTURE;
    }

    if (canvasType === 'lean') {
      return LEAN_CANVAS_STRUCTURE;
    }

    if (canvasType === 'userCentred') {
      return USER_CENTRED_DESIGN_STRUCTURE;
    }

    return BUSINESS_MODEL_STRUCTURE;
  }
}

const EditModePage = ({ match }) => {
  const canvasId = match.params.canvasId;
  const isDesktop = useMediaQuery(devicesHook.laptop);
  const [canvas] = useCanvasRetrieval(canvasId);
  const [focusedEditor, setFocusedEditor] = useState();
  const [focusedEditorRef, setFocusedEditorRef] = useState();

  useEffect(() => {
    if (isIOS) {
      // For iOS 

      const scrollTopTimeout = setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);

      let scrollCanvasTiemout;
      if (focusedEditorRef) {
        scrollCanvasTiemout = setTimeout(() => {
          focusedEditorRef.current.scrollIntoView();
        })
      }

      return () => {
        clearTimeout(scrollTopTimeout);
        if (scrollCanvasTiemout) {
          clearTimeout(scrollCanvasTiemout);
        }
      }
    }
  }, [focusedEditorRef, isDesktop]);

  const userId = useSelector(userIdSelector);

  useEffect(() => {
    setLastViewedOnCanvas(canvasId);
  }, []);

  return canvas ? (
    <>
      <Toolbar
        focusedEditor={focusedEditor}
        canvasId={canvasId}
        canvasName={canvas?.name}
      />
      <Canvas
        id={canvasId}
        canvas={canvas}
        setFocusedEditor={setFocusedEditor}
        setFocusedEditorRef={setFocusedEditorRef}
        canvasStructure={getCanvasStructure(canvas.type)}
      />
    </>
  ) : null;
};

export default EditModePage;
