import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

import Text from "./Text";
import Button from "./Button";

import { editorThemeNames } from "../constants/editor-theme";
import { devices } from "../constants/devices";
import useFirebase from "../hooks/useFirebase";

const ThemeSelector = React.forwardRef(({ canvasId }, ref) => {
  const [theme, setTheme] = useFirebase(`/canvases/${canvasId}/theme`);

  const handleOnClick = (theme) => {
    setTheme(theme);
  };

  const ThemeSelectorComponent = styled.div(() => ({
    backgroundColor: "#fff",
    borderLeft: "1px solid #121d321a",
    overflow: "auto",
    position: "absolute",
    right: "0",
    top: "4.38rem",
    width: "100%",
    [`${devices.laptop}`]: {
      width: "24rem",
      height: "calc(100% - 4.38rem)",
    },
    zIndex: "9999",
  }));

  const ThemeSelectorItem = styled.div(() => ({
    borderBottom: "1px solid #121d321a",
    display: "flex",
    justifyContent: "space-between",
    padding: "1.19rem",
  }));

  const selectedTheme = theme || editorThemeNames.DEFAULT;
  return (
    <ThemeSelectorComponent ref={ref}>
      {Object.keys(editorThemeNames).map((availableTheme, i) => (
        <ThemeSelectorItem key={i}>
          <Text>{editorThemeNames[availableTheme]}</Text>
          <Button
            variant={
              selectedTheme === editorThemeNames[availableTheme]
                ? "secondary"
                : "primary"
            }
            onClick={() => handleOnClick(editorThemeNames[availableTheme])}
          >
            {selectedTheme === editorThemeNames[availableTheme]
              ? "applied"
              : "apply"}
          </Button>
        </ThemeSelectorItem>
      ))}
    </ThemeSelectorComponent>
  );
});

ThemeSelector.propTypes = {
  canvasId: PropTypes.string.isRequired,
};

export default ThemeSelector;
