import React from 'react'
import TermsSectionHeader from './TermsSectionHeader'
import TermsSection from './TermsSection'
import Text from './Text'
import styled from '@emotion/styled'

const TextBody = styled(Text)({
  margin: '0.5rem 0 1.5rem 0 '
})

export default () => (
  <TermsSection>
    <TermsSectionHeader>
      <Text type='display'>Terms of Service</Text>
    </TermsSectionHeader>
    <Text type="title">The gist:</Text>
    <TextBody type="body">
      We, the people from P22 Limited (Based in Auckland, New Zealand), run a web
      service called Simple Canvas App and would love for you to use it. Collectively, the
      company ‘P22 Limited’ and the services we offer via the web service
      ‘Simple Canvas App’ are referred herein to as (“Simple Canvas App”).
    </TextBody>
    <TextBody type="body">
      Simple Canvas App’s service is completely free for individual users to access the standard features and predefined canvases on offer, we may offer paid plans to create custom canvas types and / or for additional features.
    </TextBody>
    <TextBody type="body">
      This Terms of Service is very thankfully adapted from a policy originally
      provided by Automattic (<a href="http://WordPress.com">WordPress.com</a>).
      Please read this notice very carefully.
    </TextBody>
    <Text type="title">Terms of Service:</Text>
    <TextBody type="body">
      The following terms and conditions (“Terms”) govern all use of the Simple Canvas App
      website(s) and all content, services, and products available at or through
      our website(s), (taken together, our “Services”). Our Services are offered
      subject to your acceptance without modification of all of the terms and
      conditions contained herein and all other operating rules, policies
      (including, without limitation, Simple Canvas App’s{' '}
      <a href="#privacy">Privacy Policy</a> ) and procedures that may be
      published from time to time by Simple Canvas App (collectively, the “Agreement”). You
      agree that we may automatically upgrade our Services, and these Terms will
      apply to any upgrades.
    </TextBody>
    <TextBody type="body">
      Please read this Agreement carefully before accessing or using our
      Services. By accessing or using any part of our Services, you agree to
      become bound by the Terms of this Agreement. If you do not agree to all
      the Terms of this Agreement, then you may not access or use any of our
      Services. If these Terms are considered an offer by Simple Canvas App, acceptance is
      expressly limited to these Terms.
    </TextBody>
    <TextBody type="body">
      Our Services are not directed to children. Access to and use of our
      Services is only for those over the age of 13 (or 16 in the European
      Union). If you are younger than this, you may not register for or use our
      Services. Any person who registers as a user or provides their personal
      information to our Services represents that they are 13 years of age or
      older (16 years or older in the European Union).
    </TextBody>
    <TextBody type="body">
      You agree to provide us with complete and accurate information when you
      register for an account. You will be solely responsible and liable for any
      activity that occurs under your username. You are responsible for keeping
      your password secure.
    </TextBody>
    <Text type="title">
      1. <a href="http://S">simplecanvas.app</a>
    </Text>
    <ul>
      <li>
        <strong>Your Simple Canvas App Account</strong>. You are responsible for maintaining
        the security of your account, and you are fully responsible for all
        activities that occur under the account and any other actions taken in
        connection. Simple Canvas App will not be liable for any acts or omissions by you, including any
        damages of any kind incurred as a result of such acts or omissions.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Google Analytics</strong>. rd), We use a third party, Google Analytics. (“Google Analytics”),
        to measure Simple Canvas App’s audience and usage. By using Simple Canvas App, you agree to assign
        the traffic relating to your account to Simple Canvas App.
      </li>
    </ul>
    <Text type="title">2. Fees, Payment, and Renewal.</Text>
    <ul>
      <li>
        <strong>Fees</strong>. Some of our Services may be offered for a fee — such
        as to create custom canvase types and or for access additional features (“Paid Services”). By using a Paid Service, you agree
        to pay the specified fees, which we’ll bill or charge you for in regular
        intervals (such as monthly, annually, or biennially), on a pre-pay basis
        until you cancel, which you can do at any time by contacting the
        relevant support team.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Payment</strong>. If your payment fails or Paid Services are
        otherwise not paid for on time, we may immediately cancel or revoke your
        access to the Paid Services. If you contact your bank or credit card
        company to decline or reverse the charge of fees for Paid Services, we
        may revoke your access to our Services in general.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Automatic Renewal</strong>. To ensure uninterrupted service, our
        Paid Services are automatically renewed. This means that unless you
        cancel a Paid Service before the end of the applicable subscription
        period, it will automatically renew, and you authorize us to invoice you
        or use any payment mechanism we have on record for you to collect the
        then-applicable subscription fee (as well as any taxes). Your Paid
        Services are renewed for the same interval of time. For example, if you
        purchase a Simple Canvas App annual plan, you will be charged each year.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Refunds</strong>. While you may cancel a Paid Service at any
        time, refunds are issued in our sole discretion.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Fee Changes.</strong> We may change our fees at any time. When
        applicable, we may give you advance notice of the fee changes. If you
        don’t agree with the fee changes, you can cancel your Paid Service.
      </li>
    </ul>
    <Text type="title">3. Third Party Services.</Text>
    <TextBody type="body">
      You may enable services, products, software (like themes or plugins), or
      applications developed by a third party or yourself (“Third Party
      Services”) on Simple Canvas App.
    </TextBody>
    <TextBody type="body">If you use any Third Party Services, you understand that:</TextBody>
    <ul>
      <li>
        Third Party Services are not vetted, endorsed, or controlled by Simple Canvas App
      </li>
    </ul>
    <ul>
      <li>
        Any use of a Third Party Service is at your own risk, and we shall not
        be responsible or liable to anyone for Third Party Services.
      </li>
    </ul>
    <ul>
      <li>
        Your use is solely between you and the respective third party (“Third
        Party”) and will be governed by the Third Party’s terms and policies. It
        is your responsibility to review the Third Party’s terms and policies
        before using a Third Party Service
      </li>
    </ul>
    <ul>
      <li>
        Some Third Party Services may request or require access to your (yours,
        your visitors’, or customers’) data. If you grant access, your data will
        handled in accordance with the Third Party’s privacy policy and
        practices. We do not have control over how a Third Party Service may use
        your data. You should carefully review Third Party Services’ data
        collection, retention, and use policies and practices before enabling
        Third Party Services.
      </li>
    </ul>
    <ul>
      <li>
        Third Party Services may not work appropriately, and we may not be able
        to provide support for issues caused by any Third Party Services.
      </li>
    </ul>
    <ul>
      <li>
        If you have questions or concerns about how a Third Party Service
        operates, or need support, please contact the Third Party directly.
      </li>
    </ul>
    <TextBody type="body">
      In rare cases, we may at our discretion, suspend, disable, or remove Third
      Party Services from your account.
    </TextBody>
    <Text type="title">6. Copyright Infringement and DMCA Policy.</Text>
    <TextBody type="body">
      As Simple Canvas App asks others to respect its intellectual property rights, it
      respects the intellectual property rights of others. If you believe that
      material located on or linked to by Simple Canvas App violates your copyright, you are
      encouraged to notify Simple Canvas App in accordance with{' '}
      <strong>Digital Millennium Copyright Act (“DMCA”)</strong> Policy. Simple Canvas App
      will respond to all such notices, including as required or appropriate by
      removing the infringing material or disabling all links to the infringing
      material. Simple Canvas App will terminate a visitor’s access to and use of the
      Services if, under appropriate circumstances, the visitor is determined to
      be a repeat infringer of the copyrights or other intellectual property
      rights. In the case of such termination, Simple Canvas App will have no obligation to
      provide a refund of any amounts previously paid to Simple Canvas App.
    </TextBody>
    <Text type="title">7. Intellectual Property.</Text>
    <TextBody type="body">
      This Agreement does not transfer from Simple Canvas App to you any Simple Canvas App or third party
      intellectual property, and all right, title, and interest in and to such
      property will remain (as between the parties) solely with Simple Canvas App. Simple Canvas App, the
      Simple Canvas App logo, and all other trademarks, service marks, graphics and logos
      used in connection with Simple Canvas App or our Services, are trademarks or registered
      trademarks of Simple Canvas App or Simple Canvas App licensors. Other trademarks, service marks,
      graphics and logos used in connection with our Services may be the
      trademarks of other third parties. Your use of our Services grants you no
      right or license to reproduce or otherwise use any Simple Canvas App or third-party
      trademarks.
    </TextBody>
    <Text type="title">8. Changes.</Text>
    <TextBody type="body">
      We are constantly updating our Services, and that means sometimes we have
      to change the legal terms under which our Services are offered. If we make
      changes that are material, we will let you know by posting on our blog, or
      by sending you an email or other communication before the changes take
      effect. The notice will designate a reasonable period of time after which
      the new terms will take effect. If you disagree with our changes, then you
      should stop using our Services within the designated notice period. Your
      continued use of our Services will be subject to the new terms. However,
      any dispute that arose before the changes shall be governed by the Terms
      (including the binding individual arbitration clause) that were in place
      when the dispute arose.
    </TextBody>
    <Text type="title">9. Termination.</Text>
    <TextBody type="body">
      Simple Canvas App may terminate your access to all or any part of our Services at any
      time, with or without cause, with or without notice, effective
      immediately. If you wish to terminate this Agreement or your Simple Canvas App account
      (if you have one), you may simply discontinue using our Services. All
      provisions of this Agreement which by their nature should survive
      termination shall survive termination, including, without limitation,
      ownership provisions, warranty disclaimers, indemnity and limitations of
      liability.
    </TextBody>
    <Text type="title">10. Disclaimer of Warranties.</Text>
    <TextBody type="body">
      Our Services are provided “as is.” Simple Canvas App and its suppliers and licensors
      hereby disclaim all warranties of any kind, express or implied, including,
      without limitation, the warranties of merchantability, fitness for a
      particular purpose and non-infringement. Neither Simple Canvas App nor its suppliers
      and licensors, makes any warranty that our Services will be error free or
      that access thereto will be continuous or uninterrupted. If you’re
      actually reading this, here’s a treat. You understand that you download
      from, or otherwise obtain content or services through, our Services at
      your own discretion and risk.
    </TextBody>
    <Text type="title">11. Jurisdiction and Applicable Law.</Text>
    <TextBody type="body">
      Except to the extent applicable law, if any, provides otherwise, this
      Agreement, any access to or use of our Services will be governed by the
      laws of New Zealand.
    </TextBody>
    <Text type="title">12. Arbitration Agreement.</Text>
    <TextBody type="body">
      Except for claims for injunctive or equitable relief or claims regarding
      intellectual property rights (which may be brought in any competent court
      without the posting of a bond), any dispute arising under this Agreement
      shall be finally settled in accordance with the Arbitration Rules of the
      New Zealand Law Society, by two arbitrators appointed in
      accordance with such Rules. The arbitration shall take place in Auckland,
      New Zealand, in the English language and the arbitral decision may be
      enforced in any court. The prevailing party in any action or proceeding to
      enforce this Agreement shall be entitled to costs and attorneys’ fees.
    </TextBody>
    <Text type="title">13. Limitation of Liability.</Text>
    <TextBody type="body">
      In no event will Simple Canvas App, or its suppliers or licensors, be liable with
      respect to any subject matter of this Agreement under any contract,
      negligence, strict liability or other legal or equitable theory for: (i)
      any special, incidental or consequential damages; (ii) the cost of
      procurement for substitute products or services; (iii) for interruption of
      use or loss or corruption of data; or (iv) for any amounts that exceed the
      fees paid by you to Simple Canvas App under this Agreement during the twelve (12) month
      period prior to the cause of action. Simple Canvas App shall have no liability for any
      failure or delay due to matters beyond their reasonable control. The
      foregoing shall not apply to the extent prohibited by applicable law.
    </TextBody>
    <Text type="title">14. General Representation and Warranty.</Text>
    <TextBody type="body">You represent and warrant that your use of our Services:</TextBody>
    <ul>
      <li>Will be in strict accordance with this Agreement;</li>
    </ul>
    <ul>
      <li>
        Will comply with all applicable laws and regulations (including without
        limitation all applicable laws regarding online conduct and acceptable
        content, the transmission of technical data exported from the United
        States or the country in which you reside, privacy, and data
        protection); and
      </li>
    </ul>
    <ul>
      <li>
        Will not infringe or misappropriate the intellectual property rights of
        any third party.
      </li>
    </ul>
    <Text type="title">15. US Economic Sanctions.</Text>
    <TextBody type="body">
      You expressly represent and warrant that your use of our Services and or
      associated services and products is not contrary to applicable U.S.
      Sanctions. Such use is prohibited, and Simple Canvas App reserves the right to
      terminate accounts or access of those in the event of a breach of this
      condition.
    </TextBody>
    <Text type="title">16. Indemnification.</Text>
    <TextBody type="body">
      You agree to indemnify and hold harmless Simple Canvas App, its contractors, and its
      licensors, and their respective directors, officers, employees, and agents
      from and against any and all claims and expenses, including attorneys’
      fees, arising out of your use of our Services, including but not limited
      to your violation of this Agreement.
    </TextBody>
    <Text type="title">17. Translation.</Text>
    <TextBody type="body">
      These Terms of Service were originally written in English (US). We may
      translate these terms into other languages. In the event of a conflict
      between a translated version of these Terms of Service and the English
      version, the English version will control.
    </TextBody>
    <Text type="title">18. Miscellaneous.</Text>
    <TextBody type="body">
      This Agreement constitutes the entire agreement between Simple Canvas App and you
      concerning the subject matter hereof, and they may only be modified by a
      written amendment signed by an authorized executive of Simple Canvas App, or by the
      posting by Simple Canvas App of a revised version.
    </TextBody>
    <TextBody type="body">
      If any part of this Agreement is held invalid or unenforceable, that part
      will be construed to reflect the parties’ original intent, and the
      remaining portions will remain in full force and effect. A waiver by
      either party of any term or condition of this Agreement or any breach
      thereof, in any one instance, will not waive such term or condition or any
      subsequent breach thereof.
    </TextBody>
    <TextBody type="body">
      You may assign your rights under this Agreement to any party that consents
      to, and agrees to be bound by, its terms and conditions; Simple Canvas App may assign
      its rights under this Agreement without condition. This Agreement will be
      binding upon and will inure to the benefit of the parties, their
      successors and permitted assigns.
    </TextBody>
  </TermsSection>
)
