import React from 'react'
import styled from '@emotion/styled'
import UserCanvas from './UserCanvas'
import { devices } from '../constants/devices'

const CanvasesGrid = ({ canvases }) => {
  const userCanvases = canvases || []

  return (
    <CanvasesContainer>
      {userCanvases.map((canvas, idx) => {
        return (
          <div key={canvas.id}>
            <UserCanvas userCanvas={canvas} />
          </div>
        )
      })}
    </CanvasesContainer>
  )
}

const CanvasesContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export default CanvasesGrid
