export const LEAN_CANVAS_STRUCTURE = {
  columns: 10,
  rows: 3,
  boxes: [
    {
      rowStart: 1,
      rowEnd: 3,
      columnStart: 1,
      columnEnd: 3,
      name: 'problem',
      title: 'Problem',
      placeholder: 'List your top 1-3 problems.',
    },
    {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 3,
      columnEnd: 5,
      name: 'solution',
      title: 'Solution',
      placeholder: 'Outline a possible solution for each problem.',
    },
    {
      rowStart: 2,
      rowEnd: 3,
      columnStart: 3,
      columnEnd: 5,
      name: 'keyMetrics',
      title: 'Key metrics',
      placeholder:
        'List the key numbers that tell you how your business is doing.',
    },
    {
      rowStart: 1,
      rowEnd: 3,
      columnStart: 5,
      columnEnd: 7,
      name: 'uniqueValueProposition',
      title: 'Unique value proposition',
      placeholder:
        'Single, clear, compelling message that states why you are different and worth paying attention.',
    },
    {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 7,
      columnEnd: 9,
      name: 'unfairAdvantage',
      title: 'Unfair advantage',
      placeholder: 'Something that cannot easily be bought or copied.',
    },
    {
      rowStart: 2,
      rowEnd: 3,
      columnStart: 7,
      columnEnd: 9,
      name: 'channels',
      title: 'Channels',
      placeholder: 'List your path to customers (inbound or outbound).',
    },
    {
      rowStart: 1,
      rowEnd: 3,
      columnStart: 9,
      columnEnd: 11,
      name: 'customerSegments',
      title: 'Customer segments',
      placeholder: 'List your target customers or users.',
    },
    {
      rowStart: 3,
      rowEnd: 4,
      columnStart: 1,
      columnEnd: 6,
      name: 'costStructure',
      title: 'Cost structure',
      placeholder: 'List your fixed and variable costs.',
    },
    {
      rowStart: 3,
      rowEnd: 4,
      columnStart: 6,
      columnEnd: 11,
      name: 'revenueStreams',
      title: 'Revenue streams',
      placeholder: 'List your sources of revenue.',
    },
  ],
}

export const FEATURE_CANVAS_STRUCTURE = {
  columns: 3,
  rows: 3,
  boxes: [
    {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 1,
      columnEnd: 2,
      name: 'idea',
      title: 'Idea description',
      placeholder:
        'Describe your idea in 2-3 sentences to keep focus and put all your team on the same page.',
    },
    {
      rowStart: 2,
      rowEnd: -1,
      columnStart: 1,
      columnEnd: 2,
      name: 'why',
      title: 'Why?',
      placeholder:
        'Why is it important to implement this idea? (both for customers and business)',
    },
    {
      rowStart: 1,
      rowEnd: 3,
      columnStart: 2,
      columnEnd: 3,
      name: 'contextualSituations',
      title: 'Contextual situations',
      placeholder:
        'At what time do people need this feature or face problems solved by this feature? what do we know about the context (place, environment, time, etc.)? are there any other participants in the process? how do these situations affect them?',
    },
    {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 3,
      columnEnd: 4,
      name: 'problems',
      title: 'Problems to solve',
      placeholder:
        'What problems are we trying to solve (both for customers and business)?',
    },
    {
      rowStart: 2,
      rowEnd: 3,
      columnStart: 3,
      columnEnd: 4,
      name: 'valueProposition',
      title: 'Value proposition',
      placeholder: 'What kind of value are we going to deliver?',
    },
    {
      rowStart: 3,
      rowEnd: 4,
      columnStart: 2,
      columnEnd: 3,
      name: 'restrictionsLimitations',
      title: 'Restrictions and limitations',
      placeholder:
        'What can prevent us from solving these problems and supporting people in these situations? (what do we have at the moment)',
    },
    {
      rowStart: 3,
      rowEnd: 4,
      columnStart: 3,
      columnEnd: 4,
      name: 'capabilities',
      title: 'Capabilities',
      placeholder:
        'What can help us to solve these problems and support people in these situations? (what do we have at the moment)',
    },
  ],
}

export const BUSINESS_MODEL_STRUCTURE = {
  columns: 10,
  rows: 3,
  boxes: [
    {
      rowStart: 1,
      rowEnd: 3,
      columnStart: 1,
      columnEnd: 3,
      name: 'keyPartners',
      title: 'Key partners',
      placeholder:
        'List the partners that you can’t do business without (not suppliers).',
    },
    {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 3,
      columnEnd: 5,
      name: 'keyActivities',
      title: 'Key activities',
      placeholder: 'What do you do every day to run your business model?',
    },
    {
      rowStart: 2,
      rowEnd: 3,
      columnStart: 3,
      columnEnd: 5,
      name: 'keyResources',
      title: 'Key resources',
      placeholder:
        'The people, knowledge, means, and money you need to run your business.',
    },
    {
      rowStart: 1,
      rowEnd: 3,
      columnStart: 5,
      columnEnd: 7,
      name: 'valuePropositions',
      title: 'Value propositions',
      placeholder:
        'What are your products and services? What is the job you get done for your customer?',
    },
    {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 7,
      columnEnd: 9,
      name: 'customerRelationships',
      title: 'Customer relationships',
      placeholder:
        'How does this show up and how do you maintain the relationship?',
    },
    {
      rowStart: 2,
      rowEnd: 3,
      columnStart: 7,
      columnEnd: 9,
      name: 'channels',
      title: 'Channels',
      placeholder:
        'How do you communicate with your customer? How do you deliver the value proposition?',
    },
    {
      rowStart: 1,
      rowEnd: 3,
      columnStart: 9,
      columnEnd: 11,
      name: 'customerSegments',
      title: 'Customer segments',
      placeholder:
        'List the top three segments. Look for segments that provide the most revenue.',
    },
    {
      rowStart: 3,
      rowEnd: 4,
      columnStart: 1,
      columnEnd: 6,
      name: 'costStructure',
      title: 'Cost structure',
      placeholder:
        'List your top costs by looking at activities and resources.',
    },
    {
      rowStart: 3,
      rowEnd: 4,
      columnStart: 6,
      columnEnd: 11,
      name: 'revenueStreams',
      title: 'Revenue streams',
      placeholder:
        'List your top three revenue streams. If you do things for free, add them here too.',
    },
  ],
}

export const USER_CENTRED_DESIGN_STRUCTURE = {
  rows: 8,
  columns: 5,
  boxes: [
    {
      rowStart: 1,
      rowEnd: 9,
      columnStart: 1,
      columnEnd: 2,
      name: 'problems',
      title: '3. Problems',
      placeholder:
        'What are the hypothetical or existing problems the users may want to solve with the features or services.',
    },
    {
      rowStart: 1,
      rowEnd: 5,
      columnStart: 2,
      columnEnd: 3,
      name: 'motives',
      title: '4. Motives',
      placeholder:
        'What emotional drivers may motive users to engage with the business.',
    },
    {
      rowStart: 5,
      rowEnd: 9,
      columnStart: 2,
      columnEnd: 3,
      name: 'fears',
      title: '5. Fears',
      placeholder:
        'What possible anxieties the users may feel when engaging with the business.',
    },
    {
      rowStart: 1,
      rowEnd: 3,
      columnStart: 3,
      columnEnd: 4,
      name: 'business',
      title: '5. Business',
      placeholder:
        'Short description of the analysed organisation, brand or product.',
    },
    {
      rowStart: 3,
      rowEnd: 6,
      columnStart: 3,
      columnEnd: 4,
      name: 'users',
      title: '2. Users',
      placeholder: 'List all types of potential or existing target users.',
    },
    {
      rowStart: 6,
      rowEnd: 9,
      columnStart: 3,
      columnEnd: 4,
      name: 'uniqueValueProposition',
      title: '9. Unique value proposition',
      placeholder:
        'A single phrase describing the business promise to its users.',
    },
    {
      rowStart: 1,
      rowEnd: 5,
      columnStart: 4,
      columnEnd: 5,
      name: 'competitiveAdvantage',
      title: '8. Competitive advantage',
      placeholder:
        'Features or characteristics that distinguish the business from its competitors.',
    },
    {
      rowStart: 5,
      rowEnd: 9,
      columnStart: 4,
      columnEnd: 5,
      name: 'alternatives',
      title: '7. Alternatives',
      placeholder:
        'List all possible alternatives the users may choose instead of the analysed business.',
    },
    {
      rowStart: 1,
      rowEnd: 9,
      columnStart: 5,
      columnEnd: 6,
      name: 'solutions',
      title: '6. Solutions',
      placeholder:
        'Describe both existing and hypothetical ways in which the analysed business can solve the users problems.',
    },
  ],
}

export const MARKET_VALIDATION_STRUCTURE = {
  rows: 3,
  columns: 3,
  boxes: [
    {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 1,
      columnEnd: 2,
      name: 'firstPersonName',
      title: 'Name',
      placeholder: 'Provide here the name of the first person',
    },
    {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 2,
      columnEnd: 3,
      name: 'secondPersonName',
      title: 'Name',
      placeholder: 'Provide here the name of the second person',
    },
    {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 3,
      columnEnd: 4,
      name: 'thirdPersonName',
      title: 'Name',
      placeholder: 'Provide here the name of the third person',
    },
    {
      rowStart: 2,
      rowEnd: 3,
      columnStart: 1,
      columnEnd: 2,
      name: 'firstPersonContext',
      title: 'Context',
      placeholder:
        'Who is the person? What does he/she do for a living? How is he/she related to the idea? Add any other relevant context',
    },
    {
      rowStart: 2,
      rowEnd: 3,
      columnStart: 2,
      columnEnd: 3,
      name: 'secondPersonContext',
      title: 'Context',
      placeholder:
        'Who is the person? What does he/she do for a living? How is he/she related to the idea? Add any other relevant context',
    },
    {
      rowStart: 2,
      rowEnd: 3,
      columnStart: 3,
      columnEnd: 4,
      name: 'thirdPersonContext',
      title: 'Context',
      placeholder:
        'Who is the person? What does he/she do for a living? How is he/she related to the idea? Add any other relevant context',
    },
    {
      rowStart: 3,
      rowEnd: 4,
      columnStart: 1,
      columnEnd: 2,
      name: 'firstOutcome',
      title: 'Outcome of the discussion',
      placeholder:
        'What did he/she like about the idea? What are their specific pains? How this idea would help them?',
    },
    {
      rowStart: 3,
      rowEnd: 4,
      columnStart: 2,
      columnEnd: 3,
      name: 'secondOutcome',
      title: 'Outcome of the discussion',
      placeholder:
        'What did he/she like about the idea? What are their specific pains? How this idea would help them?',
    },
    {
      rowStart: 3,
      rowEnd: 4,
      columnStart: 3,
      columnEnd: 4,
      name: 'thirdOutcome',
      title: 'Outcome of the discussion',
      placeholder:
        'What did he/she like about the idea? What are their specific pains? How this idea would help them?',
    },
  ],
}
