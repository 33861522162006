import React from 'react'
import styled from '@emotion/styled'

const Header = styled.div({
    width: '100%',
    textAlign: 'center',
    margin: '2rem 0'
})

const TermsSectionHeader = ({children}) => {
    return (
        <Header>
            {children}
        </Header>
    )
}

export default TermsSectionHeader
