import React, { useEffect } from "react";
import styled from "@emotion/styled";

import useFirebase from "../hooks/useFirebase";
import { devices } from "../constants/devices";

const CanvasTitle = ({ canvasId }) => {
  const [name, setName] = useFirebase(`/canvases/${canvasId}/name`);

  useEffect(() => {
    if (name === null) setName("");
  }, [name]);

  return (
    <Input
      value={name}
      onChange={(e) => setName(e.target.value)}
      placeholder={
        name === undefined ? "Loading..." : "Type a name"
      }
    />
  );
};
const Input = styled.input({
  fontFamily: "inherit",
  fontSize: "0.9rem",
  lineHeight: 1,
  fontWeight: 500,
  border: "none",
  outline: "none",
  [`${devices.laptop}`]: {
    fontSize: "1.625rem",
    lineHeight: 1.16,
  },
});

export default CanvasTitle;
