import React from 'react';
import styled from '@emotion/styled';
import PropTypes, { arrayOf } from 'prop-types';

const ErrorsDisplay = ({ errors }) => (
  <ErrorsContainer>
    {errors.map((formError) => <ErrorDisplay key={formError}>{formError}</ErrorDisplay>)}
  </ErrorsContainer>
);

const ErrorsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 50,
});

const ErrorDisplay = styled.div({
  borderRadius: 25,
  backgroundColor: '#FF3D00',
  color: 'white',
  padding: 5,
  marginBottom: 5,
});

ErrorsDisplay.propTypes = {
  errors: arrayOf(PropTypes.string),
};

ErrorsDisplay.defaultProps = {
  errors: [],
};

export default ErrorsDisplay;
