import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Flex } from 'rebass';
import { useMediaQuery } from '@react-hook/media-query';
import { MdKeyboardArrowUp } from 'react-icons/md';
import Text from '../components/Text';
import ButtonBase from '../components/Button';
import ButtonGroup from '../components/ButtonGroup';
import { userIdSelector, isAnonSelector } from '../../auth/authSelectors';
import CanvasPreview from '../components/CanvasPreview';
import { canvasTypes } from '../../variables';
import { createCanvas, logOut } from '../firebase';
import useFirebase from '../hooks/useFirebase';
import useScrollTo from '../hooks/useScrollTo';
import CanvasesGrid from '../components/CanvasesGrid';
import theme from '../constants/theme';
import Navbar from '../components/Navbar';
import { devices, devicesHook } from '../constants/devices';
import LibraryMenu from '../components/LibraryMenu';
import ContactUs from '../components/ContactUs';
import IconButton from '../components/IconButton';
import useScrollPosition from '../hooks/useScrollPosition';

const getAllCanvases = (canvasesObject) => Object.entries(canvasesObject || {})
  .sort((a, b) => (b[1].dateLastViewed || 0) - (a[1].dateLastViewed || 0))
  .map((pair) => ({ id: pair[0], shared: pair[1].shared }));
const getSharedCanvases = (canvasesObject) => Object.entries(canvasesObject || {})
  .filter(([key, value]) => value.shared)
  .sort((a, b) => (b[1].dateLastViewed || 0) - (a[1].dateLastViewed || 0))
  .map((pair) => ({ id: pair[0], shared: pair[1].shared }));
const getMyCanvases = (canvasesObject) => Object.entries(canvasesObject || {})
  .filter(([key, value]) => !value.shared)
  .sort((a, b) => (b[1].dateLastViewed || 0) - (a[1].dateLastViewed || 0))
  .map((pair) => ({ id: pair[0], shared: pair[1].shared }));

const LibraryPage = ({ userId, isAnon, create }) => {
  const [userCanvases] = useFirebase(`/users/${userId}/`);
  const [canvasesToShow, setCanvasesToShow] = useState(
    getAllCanvases(userCanvases),
  );
  const [activeFilter, setActiveFilter] = useState('ALL');
  const [fetchStatus, setFetchStatus] = useState('LOADING');
  const matches = useMediaQuery(devicesHook.laptop);
  const history = useHistory();

  useScrollPosition(({ prevPos, currPos }) => {
    const changeSectionPosition = separatorRef.current.getBoundingClientRect().top;
    if (separatorRef.current && Math.abs(currPos.y) > changeSectionPosition) { setActiveSectionCanvases(false); } else { setActiveSectionCanvases(true); }
  }, [activeSectionCanvases]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userCanvases) {
      setFetchStatus('CANVAS');
      setCanvasesToShow(getAllCanvases(userCanvases));
    } else setCanvasesToShow([]);
    if (userCanvases === null) setFetchStatus('NO_CANVAS');
  }, [userCanvases]);


  const location = useLocation();

  const [scrollToCreate, separatorRef] = useScrollTo();
  const [scrollToTop, topRef] = useScrollTo();

  const [activeSectionCanvases, setActiveSectionCanvases] = useState(true);
  const onCreateCanvasesClick = () => {
    scrollToCreate(100);
    setActiveSectionCanvases(false);
  };
  const onMyCanvasesClick = () => {
    scrollToTop();
    setActiveSectionCanvases(true);
  };

  return (
    <Container ref={topRef}>
      <LibraryMenu
        handleCreateCanvas={onCreateCanvasesClick}
        isAnon={isAnon}
        location={location}
      />
      <Navbar
        position={isAnon ? 149 : 310}
        style={{
          borderLeft: '1px solid black',
          borderRight: '1px solid black',
          borderBottom: '1px solid black',
        }}
      >
        {activeSectionCanvases

          ? (
            <FlexContainerBottom>
              <div>
                <Button
                  onClick={onMyCanvasesClick}
                  variant={activeSectionCanvases ? 'secondary' : 'primary'}
                >
                  my canvases
                </Button>
                <ButtonBase onClick={onCreateCanvasesClick}>
                  {matches ? 'create a new canvas' : 'create canvases'}
                </ButtonBase>
              </div>
              <div>
                <ButtonGroup
                  buttons={[
                    {
                      placeholder: 'all',
                      variant: activeFilter === 'ALL' ? 'secondary' : 'primary',
                      onClick: () => {
                        setActiveFilter('ALL');
                        setCanvasesToShow(getAllCanvases(userCanvases));
                      },
                    },
                    {
                      placeholder: 'mine',
                      variant: activeFilter === 'MINE' ? 'secondary' : 'primary',
                      onClick: () => {
                        setActiveFilter('MINE');
                        setCanvasesToShow(getMyCanvases(userCanvases));
                      },
                    },
                    {
                      placeholder: matches ? 'shared with me' : 'shared',
                      variant: activeFilter === 'SHARED' ? 'secondary' : 'primary',
                      onClick: () => {
                        setActiveFilter('SHARED');
                        setCanvasesToShow(getSharedCanvases(userCanvases));
                      },
                    },
                  ]}
                />
              </div>
            </FlexContainerBottom>
          )
          : (
            <FlexContainerBottom>
              <FlexCenter>
                <Button onClick={onMyCanvasesClick}>my canvases</Button>
                <Button
                  onClick={onCreateCanvasesClick}
                  variant={!activeSectionCanvases ? 'secondary' : 'primary'}
                >
                  create canvases
                </Button>
              </FlexCenter>
              <Flex>
                {matches && Object.entries(canvasTypes).map(([key, data]) => (
                  <Button
                    key={key}
                    onClick={() => {
                      createCanvas(key, userId, (id) => {
                        history.push(`/canvas/${id}`);
                      });
                    }}
                  >
                    {`+ ${data.name}`}
                  </Button>
                ))}
                <IconButton onClick={onMyCanvasesClick}>
                  <MdKeyboardArrowUp style={matches ? { fontSize: '1.3rem' } : { fontSize: '1rem' }} />
                </IconButton>
              </Flex>
            </FlexContainerBottom>
          )}
      </Navbar>
      {fetchStatus === 'LOADING' ? (
        <CanvasStatusTitle>loading your canvases...</CanvasStatusTitle>
      ) : (
        <>
          {fetchStatus === 'NO_CANVAS' && (
            <CanvasStatusTitle>
              you do not have any canvas yet, try creating one!
            </CanvasStatusTitle>
          )}
          <CanvasesGrid canvases={canvasesToShow} />
        </>
      )}
      <CreateCanvasSeparator ref={separatorRef}>
        <Text type="title">Create a new canvas</Text>
      </CreateCanvasSeparator>
      <PreviewsSection>
        {Object.entries(canvasTypes).map(([key, data], index) => (
          <ContentContainer index={index} key={key}>
            <CanvasPreview
              title={data.name}
              about={data.about}
              createAction={() => {
                createCanvas(key, userId, (id) => {
                  history.push(`/canvas/${id}`);
                });
              }}
            />
          </ContentContainer>
        ))}
        <ContentContainer index="1">
          <div>&nbsp;</div>
        </ContentContainer>
        <ContentContainer index="0">
          <ContactUs type="primary" />
        </ContentContainer>

      </PreviewsSection>
    </Container>
  );
};

const Container = styled.div({
  padding: 20,
});

const FlexCenter = styled(Flex)({
  alignItems: 'center',
});

const Button = styled(ButtonBase)(() => ({
  marginRight: 5,
  [`${devices.laptop}`]: {
    marginRight: 10,
  },
}));

const FlexContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const FlexContainerBottom = styled(FlexContainer)(() => ({
  flex: 1,
}));

const PreviewsSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  [`${devices.laptop}`]: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
});

const CreateCanvasSeparator = styled.div({
  marginTop: 10,
  marginBottom: 10,
  textAlign: 'center',
});

const CanvasStatusTitle = styled(Text)(() => ({
  textAlign: 'center',
  marginTop: 10,
  marginBottom: 5,
  fontSize: theme.fontSizes[5],
}));

const ContentContainer = styled.div(({ index }) => {
  if (index % 2 === 0) {
    return {
      [`${devices.laptop}`]: {
        marginRight: 10,
        flex: '1 40%',
      },
    };
  }
  return {
    [`${devices.laptop}`]: {
      marginLeft: 10,
      flex: '1 40%',
    },
  };
});

export default connect(
  (state) => ({
    userId: userIdSelector(state),
    isAnon: isAnonSelector(state),
  }),
)(LibraryPage);
