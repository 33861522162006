import React, { useState } from "react";
import { Link } from "react-router-dom";
import { createCanvas, logOut } from "../firebase";
import styled from "@emotion/styled";
import { devices, devicesHook } from "../constants/devices";
import { Flex } from "rebass";

import Text from "./Text";
import ButtonBase from "./Button";
import { useMediaQuery } from "@react-hook/media-query";
import { mailToUs } from '../utils'; 

const LibraryMenu = ({ isAnon, location, handleCreateCanvas }) => {
  const [showMenu, setShowMenu] = useState(false);
  const matches = useMediaQuery(devicesHook.laptop);

  const Options = () => {
    return (
      <OptionsContainer>
        {isAnon ? (
          <>
            <Link
              to={{
                pathname: "/signin",
                state: {
                  background: location,
                },
              }}
            >
              <Button>sign in</Button>
            </Link>
            <Link
              to={{
                pathname: "/signin",
                state: {
                  background: location,
                },
              }}
            >
              <Button>create an account</Button>
            </Link>
          </>
        ) : (
          <>
            <OptionsGroup>
              <Link
                to={{
                  pathname: "/settings",
                  state: {
                    background: location,
                  },
                }}
              >
                <Button>settings</Button>
              </Link>
              <Button onClick={mailToUs}>contact us</Button>
            </OptionsGroup>

            <Button onClick={logOut}>log out</Button>
          </>
        )}
      </OptionsContainer>
    );
  };

  const ResponsiveMenu = () => {
    return (
      <div>
        <ContainerDesktop>
          <Options />
        </ContainerDesktop>
        <Container>
          <Button
            variant={showMenu ? "secondary" : "primary"}
            onClick={() => setShowMenu((prevState) => !prevState)}
          >
            menu
          </Button>
        </Container>
      </div>
    );
  };

  return (
    <Header>
      <FlexContainer>
        <TitleContainer flexDirection="column" isAnon={isAnon}>
          <Text>simple canvas app</Text>
          <div>
            {(matches || !showMenu) && !isAnon && (
              <MarginTopButton variant="secondary" onClick={handleCreateCanvas}>
                + create new canvas
              </MarginTopButton>
            )}
          </div>
          {isAnon && (
            <Text type={matches ? "title" : "body"} variant="grey">
              please <u>create an account</u> to save, share, and collaborate on
              canvases.
            </Text>
          )}
        </TitleContainer>
        <ResponsiveMenu />
      </FlexContainer>
      {showMenu && !matches && <Options />}
    </Header>
  );
};

const OptionsContainer = styled.div({
  marginTop: 0,
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid black",
  [`${devices.laptop}`]: {
    marginTop: 0,
    borderBottom: "none",
  },
  margin: "10px -20px",
  padding: "0 20px",
  paddingBottom: "10px",
});

const OptionsGroup = styled.div({
  display: "flex",
});

const Container = styled.div({
  display: "flex",

  [`${devices.laptop}`]: {
    display: "none",
  },
});

const ContainerDesktop = styled.div({
  display: "none",
  [`${devices.laptop}`]: {
    display: "flex",
  },
});

const Header = styled.div(({ isAnon }) => ({
  padding: 20,
  borderTop: "1px solid black",
  borderRight: "1px solid black",
  borderLeft: "1px solid black",
  height: isAnon ? 45 : 140,
  [`${devices.laptop}`]: {
    height: isAnon ? 45 : 250,
  },
}));

const TitleContainer = styled(Flex)(({ isAnon }) => ({}));

const FlexContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
});

const Button = styled(ButtonBase)(() => ({
  marginRight: 5,
  [`${devices.laptop}`]: {
    marginRight: 10,
  },
}));

const MarginTopButton = styled(ButtonBase)(() => ({
  marginTop: 10,
  [`${devices.laptop}`]: {
    marginTop: 20,
  },
}));

export default LibraryMenu;
