import React, { useRef, useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { FaChevronLeft } from 'react-icons/fa';
import { useMediaQuery } from '@react-hook/media-query';
import IconButton from './IconButton';
import Button from './Button';
import CanvasTitle from './CanvasTitle';
import { devices, devicesHook } from '../constants/devices';
import ThemeSelector from './ThemeSelector';
import useClickOutside from '../hooks/useClickOutside';
import ToolbarControls from './ToolbarControls';


const Toolbar = ({ focusedEditor, canvasId, canvasName }) => {
  const [showThemeSelector, setShowThemeSelector] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const isDesktop = useMediaQuery(devicesHook.laptop);
  const themeContainerRef = useRef(null);
  const toggleThemeSelectorButtonRef = useRef(null);
  useClickOutside(
    themeContainerRef,
    [toggleThemeSelectorButtonRef],
    () => setShowThemeSelector(false),
  );

  const handleShareLink = (link, name) => {
    const currPath = history.location.pathname;
    history.push({
      pathname: `${currPath}/share`,
      state: { background: location, link, name },
    });
  };

  return (
    <>
      <ToolbarComponent>
        <TitleGroup>
          <IconsGroup>
            <Link to="/library">
              <MarginIconButton>
                <FaChevronLeft />
              </MarginIconButton>
            </Link>
          </IconsGroup>
          <CanvasTitle canvasId={canvasId} />
        </TitleGroup>
        {isDesktop && <ToolbarControls focusedEditor={focusedEditor} />}
        <ButtonsGroup>
          <MarginButton
            ref={toggleThemeSelectorButtonRef}
            variant={showThemeSelector ? 'secondary' : 'primary'}
            onClick={() => {
              setShowThemeSelector(!showThemeSelector);
            }}
          >
            theme
          </MarginButton>
          <MarginButton
            type="small"
            onClick={() => {
              const actualLocation = window.location.href;
              setShowThemeSelector(false);
              handleShareLink(`${actualLocation}/sh`, canvasName);
            }}
          >
            share
          </MarginButton>
        </ButtonsGroup>
      </ToolbarComponent>
      { showThemeSelector && <ThemeSelector ref={themeContainerRef} canvasId={canvasId} /> }
      {!isDesktop && <ToolbarControls focusedEditor={focusedEditor} />}
    </>
  );
};

const ButtonsGroup = styled.div({
  display: 'flex',
  margin: '0.625rem',
  height: 'fit-content',
});

const IconsGroup = styled.div({
  display: 'flex',

  margin: '0.625rem 0.4063rem',
  [`${devices.laptop}`]: {
    margin: '0.625rem',
  },
});


const MarginIconButton = styled(IconButton)({
  margin: '0 0.125rem',
  [`${devices.laptop}`]: {
    margin: '0 0.3125rem',
  },
});

const MarginButton = styled(Button)({
  margin: '0 0.156rem',
  [`${devices.laptop}`]: {
    margin: '0 0.3125rem',
  },
});

const TitleGroup = styled.div({
  display: 'flex',
  alignItems: 'center',
  WebkitBoxAlign: 'center',
  '-ms-flex-align': 'center',
});

const ToolbarComponent = styled.div({
  background: 'white',
  borderBottom: '1px solid #121d321a',
  padding: 5,
  minHeight: '4.375rem',
  '@media print': { display: 'none' },
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

Toolbar.propTypes = {
  canvasId: PropTypes.string.isRequired,
  canvasName: PropTypes.string.isRequired,
  focusedEditor: PropTypes.shape({
    editorState: PropTypes.object,
    handleChange: PropTypes.func,
  }).isRequired,
};

export default Toolbar;
