import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import { useLocation, useHistory } from "react-router-dom";
import Button from "../components/Button";
import Popup from "../components/Popup";
import Input from "../components/Input";
import { sendPasswordResetEmail } from "../firebase";
import useRedirectAfterLogin from "../hooks/useRedirectAfterLogin";
import * as Yup from "yup";
import { useFormik } from "formik";
const Divider = styled.div({
  height: "1px",
  width: "100%",
  background: "#121D32",
  margin: "1.25rem 0",
  opacity: "0.2",
});

const MarginButton = styled(Button)(() => ({
  width: "100%",
  marginTop: "1.25rem",
}));

const Validation = Yup.object().shape({
  mail: Yup.string()
    .matches(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      "Please enter a valid email."
    )
    .required("enter email"),
});

const ForgotPasswordPage = ({ location: { state } }) => {
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);

  useRedirectAfterLogin();

  const handleResetPassword = ({ mail }) => {
    sendPasswordResetEmail(mail);
    setSending(true);
    setTimeout(() => setSent(true), 1500);
  };

  const formik = useFormik({
    initialValues: {
      mail: state.mail,
    },
    onSubmit: handleResetPassword,
    validationSchema: Validation,
  });

  const getButtonText = () => {
    if (!sent && !sending) {
      return "reset password";
    }

    if (sending && !sent) {
      return "sending...";
    }

    if (sending && sent) {
      return "check your emails";
    }
  };

  return (
    <Popup>
      <Divider />
      <Input
        placeholder={
          formik.errors.mail && formik.touched.mail
            ? formik.errors.mail
            : "email"
        }
        name="mail"
        value={formik.values.mail}
        onChange={formik.handleChange}
        error={formik.touched.mail ? formik.errors.mail : null}
      />
      <MarginButton
        onClick={formik.handleSubmit}
        type="large"
        variant="secondary"
      >
        {getButtonText()}
      </MarginButton>
    </Popup>
  );
};

export default ForgotPasswordPage;
