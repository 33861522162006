import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { useDispatch } from 'react-redux'
import Popup from '../components/Popup'
import Input from '../components/Input'
import Button from '../components/Button'
import Checkbox from '../components/Checkbox'
import { useHistory } from 'react-router-dom'

import { upgradeAnonUser } from '../firebase/index'
import useRedirectAfterLogin from '../hooks/useRedirectAfterLogin'
import ErrorsDisplay from '../components/ErrorsDisplay'

const Validation = Yup.object().shape({
  mail: Yup.string()
    .matches(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      'Please enter a valid email.'
    )
    .required('enter email'),
  name: Yup.string().required('enter name'),
  password: Yup.string()
    .min(6, 'Password must have at least 6 characters.')
    .required('enter password'),
  terms: Yup.boolean().oneOf([true], 'must accept terms & conditions'),
})

const SignUpPage = ({ location: { state } }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  useRedirectAfterLogin()

  const handleSignIn = async ({ mail, password, name, terms }) => {
    await upgradeAnonUser(dispatch)(mail, password, name)
  }

  const formik = useFormik({
    initialValues: {
      mail: state.mail,
      password: '',
      name: '',
      terms: false,
    },
    onSubmit: handleSignIn,
    validationSchema: Validation,
    validateOnChange: false,
  })

  const errors = Object.values(formik.errors)

  return (
    <Popup>
      <Container>
        <ErrorsDisplay errors={errors} />
        <Input
          placeholder={
            formik.errors.mail && formik.touched.mail
              ? formik.errors.mail
              : 'email'
          }
          name="mail"
          prefixIcon="arrow-left"
          prefixHandler={history.goBack}
          value={formik.values.mail}
          onChange={formik.handleChange}
          error={formik.touched.mail ? formik.errors.mail : null}
        />
        <MarginInput
          type="password"
          placeholder={
            formik.errors.password && formik.touched.password
              ? formik.errors.password
              : 'password'
          }
          name="password"
          onChange={formik.handleChange}
          error={formik.touched.password ? formik.errors.password : null}
        />
        <MarginInput
          placeholder={
            formik.errors.name && formik.touched.name
              ? formik.errors.name
              : 'your full name'
          }
          name="name"
          onChange={formik.handleChange}
          error={formik.touched.name ? formik.errors.name : null}
        />
        <MarginCheckbox>
          <Checkbox
            name="terms"
            onChange={formik.handleChange}
            error={formik.errors.terms}
          />
        </MarginCheckbox>
        <MarginButton
          type="large"
          variant="secondary"
          onClick={formik.handleSubmit}
        >
          sign up
        </MarginButton>
      </Container>
    </Popup>
  )
}

const MarginInput = styled(Input)(() => ({
  marginTop: '1.25rem',
}))

const MarginButton = styled(Button)(() => ({
  marginTop: '1.25rem',
}))

const MarginCheckbox = styled.div(() => ({
  marginTop: '1.25rem',
}))

const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
})

SignUpPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
}

export default SignUpPage
