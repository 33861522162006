import { createStore } from 'redux'
import authActions from './constants';

const userReducer = (state = { key : 'value' }, action) => {
  switch (action.type) {
    case authActions.SIGN_IN:
      return {
        ...state,
        user: action.payload,
      }
    case authActions.SIGN_OUT:
      return {
        ...state,
        user: null,
      }
    case authActions.UPGRADE_ANON:
      return {
        ...state,
        user: action.payload,
      }
    case authActions.UPDATE_DISPLAY_NAME:
      return {
        ...state,
        user: {
          ...state.user,
          displayName: action.payload,
        },
      }
    default:
      return state;
  }
}

const store = createStore(userReducer)
export default store;
