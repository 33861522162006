import { useEffect } from "react";

/**
 * Hook that execute a callback when clicks outside of the passed ref and the excluded refs
 */
const useClickOutside = (ref, excludeRefs=[] ,callback) => {
    useEffect(() => {
      /**
       * Execute callback if clicked on outside of element and outside excluded elements
       */
      function handleClickOutside(event) {
        if (ref.current && !clickInsideRef(ref, event.target) && !clickInsideExcludedRef(excludeRefs, event.target) ) {
            callback();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }


  const clickInsideExcludedRef = (refs, target) => {
    return refs.some(r => r.current.contains(target))
  }
  
  const clickInsideRef = (ref, target) => {
      return ref.current.contains(target)
  }

  export default useClickOutside;