import React from 'react'
import styled from '@emotion/styled'
import { devices } from '../constants/devices'

const Container = styled.div({
    width: '100%',
    padding: '.625rem',
    [`${devices.laptop}`]: {
        width: '70%',
        padding: '1rem',
        margin: '0 auto'
    }
})

const TermsSection = ({children}) => {
    return (
        <Container>{children}</Container>
    )
}

export default TermsSection
