export const editorThemeNames = {
    DEFAULT: 'default',
    // LITE: 'lite',
    NOTEPAD: 'notepad',
    INVERTED: 'inverted',
    ROUNDED: 'rounded',
}

export const editorThemes = {
    [`${editorThemeNames.DEFAULT}`]: {
        background: 'white',
        backgroundBox: 'white',
        borderRadius: '0',
        border: '#121D32',
        gap: '0',
        text: '#121D32',
    },
    // [`${editorThemeNames.LITE}`]: {
    //     background: 'white',
    //     border: '#121D32',
    //     text: '#121D32',
    // },
    [`${editorThemeNames.NOTEPAD}`]: {
        background: '#FFFDCC',
        backgroundBox: '#FFFDCC',
        borderRadius: '0',
        border: '#929292',
        gap: '0',
        text: '#929292'
    },
    [`${editorThemeNames.INVERTED}`]: {
        background: '#121D32',
        backgroundBox: '#121D32',
        borderRadius: '0',
        border: 'white',
        gap: '0',
        text: 'white'
    },
    [`${editorThemeNames.ROUNDED}`]: {
        background: '#efefef',
        backgroundBox: 'white',
        borderRadius: '10px',
        border: '#e2e2e2',
        gap: '0.625rem',
        text: '#121D32'
    },
}