import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'emotion-theming';

import { useTrackPageview } from './analytics';
import App from './App';
import store from './store/store';
import theme from './constants/theme';

firebase.initializeApp({
  apiKey: 'AIzaSyBHc0hChV-HVE0dhU2MWxErlAq8rUgs7R0',
  authDomain: 'lean-e89fc.firebaseapp.com',
  databaseURL: 'https://lean-e89fc.firebaseio.com',
  projectId: 'lean-e89fc',
  storageBucket: 'lean-e89fc.appspot.com',
  messagingSenderId: '1032392049269',
});

const AppContainer = () => {
  useTrackPageview();

  return (
    <StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
              <App />
        </Provider>
      </ThemeProvider>
    </StrictMode>
  );
};

export default AppContainer;
