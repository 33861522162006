import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';
import { userIdSelector } from '../../auth/authSelectors';
import { addCanvasToUserCollection } from '../firebase';
import Text from '../components/Text';

const ShareCanvas = ({ match }) => {
  const userId = useSelector((state) => userIdSelector(state));
  const { canvasId } = match.params;
  const history = useHistory();
  const [error, setError] = useState('');

  useEffect(() => {
    if (userId && canvasId) {
      addCanvasToUserCollection(canvasId, userId)
        .then(() => history.push(`/canvas/${canvasId}`))
        .catch((e) => {
          console.log(e);
          setError(e);
        });
    }
  }, [userId, canvasId]);

  return (
    <CanvasStatus>
      {error ? (
        <Text>Sorry, something went wrong :(</Text>
      ) : (
        <Text>Redirecting to your canvas...</Text>
      )}
    </CanvasStatus>
  );
};

const CanvasStatus = styled.div({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export default ShareCanvas;
