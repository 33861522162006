import { useEffect } from 'react';
import firebase from 'firebase/app';
import { signIn, signOut } from '../app/store/actions';

export const useWatchAuth = (dispatch) => {

  useEffect(
    () => firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch(
          signIn({
            displayName: user.displayName,
            email: user.email,
            emailVerified: user.emailVerified,
            photoURL: user.photoURL,
            uid: user.uid,
            // phoneNumber: user.phoneNumber,
            isAnonymous: user.isAnonymous,
            providerData: user.providerData,
          }),
        );
      } else {
        dispatch(signOut());
        console.log('Signed Out');
        firebase.auth().signInAnonymously();
      }
    }),
    [dispatch, firebase.auth()],
  );
};
