import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { MdLink, MdChevronLeft } from 'react-icons/md';
import { useMediaQuery } from '@react-hook/media-query';
import IconButton from './IconButton';
import Input from './Input';
import Button from './Button';
import { devices, devicesHook } from '../constants/devices';

const LinkBox = styled.div({
  border: '1px solid #E7E8EB',
  borderRadius: '2px',
  boxSizing: 'border-box',
  display: 'flex',
  position: 'absolute',
  background: 'white',
  zIndex: '99',
  width: '100%',
  top: 0,
  left: '-5px',
  padding: '0.625rem 0.625rem 0.625rem 0.3125rem',
  [`${devices.laptop}`]: {
    top: '60px',
    left: '30%',
    width: '28.375rem',
    padding: '1.25rem 1.25rem 1.25rem 0.625rem',
  },
});

const FormContainer = styled.div({
  display: 'flex',
  width: '100%',
});

const MarginInput = styled(Input)({
  margin: '0 0.625rem',
  flex: '1',
});

const Hyperlink = ({ handleAddLink }) => {
  const [showInput, setShowInput] = useState(false);
  const [link, setLink] = useState('');
  const matches = useMediaQuery(devicesHook.laptop);

  const handleLinkChange = useCallback(
    (e) => {
      setLink(e.target.value);
    },
    [setLink],
  );

  const toggleShowInput = () => {
    setShowInput((prevValue) => !prevValue);
  };

  const handleSubmit = () => {
    toggleShowInput();
    handleAddLink(link);
    setLink('');
  };

  return (
    <>
      <IconButton
        tooltipText="add link"
        onMouseDown={(e) => {
          e.preventDefault();
          toggleShowInput();
        }}
      >
        <MdLink />
      </IconButton>
      {showInput && (
        <LinkBox>
          {!matches && (
            <IconButton onClick={toggleShowInput}>
              <MdChevronLeft />
            </IconButton>
          )}
          <FormContainer>
            <MarginInput
              placeholder="http://typeyourlinkhere.com"
              value={link}
              onChange={handleLinkChange}
              small
              type="text"
              flexGrow
            />
            <Button onClick={handleSubmit} variant="secondary">
              add&nbsp;
            </Button>
          </FormContainer>
        </LinkBox>
      )}
    </>
  );
};

export default Hyperlink;
