import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { RichUtils, Modifier, EditorState } from 'draft-js';
import { isIOS } from 'react-device-detect';
import Hyperlink from './Hyperlink';
import { devices } from '../constants/devices';
import ColorSelector from './ColorSelector';
import IconButton from './IconButton';
import { editorControls } from '../../variables';


const ToolbarControls = ({ focusedEditor }) => {
  const toggleInlineStyle = (style) => {
    const { handleChange, editorState } = focusedEditor;
    handleChange(RichUtils.toggleInlineStyle(editorState, style));
  };
  const toggleBlockType = (style) => {
    const { handleChange, editorState } = focusedEditor;
    handleChange(RichUtils.toggleBlockType(editorState, style));
  };

  const toggleColor = (toggledColor) => {
    const { handleChange, editorState } = focusedEditor;
    const selection = editorState.getSelection();
    // Let's just allow one color at a time. Turn off all active colors.
    const nextContentState = Object.keys(editorControls.colors).reduce(
      (contentState, color) => Modifier.removeInlineStyle(contentState, selection, color),
      editorState.getCurrentContent(),
    );
    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      'change-inline-style',
    );
    const currentStyle = editorState.getCurrentInlineStyle();
    // Unset style override for current color.
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce(
        (state, color) => RichUtils.toggleInlineStyle(state, color),
        nextEditorState,
      );
    }
    // If the color is being toggled on, apply it.
    if (!currentStyle.has(toggledColor)) {
      nextEditorState = RichUtils.toggleInlineStyle(
        nextEditorState,
        toggledColor,
      );
    }
    handleChange(nextEditorState);
  };

  const handleLink = (link) => {
    const { handleChange, editorState } = focusedEditor;
    const selection = editorState.getSelection();
    if (!link) {
      handleChange(RichUtils.toggleLink(editorState, selection, null));
    }

    const content = editorState.getCurrentContent();
    const completeLink = link.indexOf('http://') === -1 && link.indexOf('https://') === -1
      ? `http://${link}`
      : link;

    const contentWithEntity = content.createEntity('LINK', 'MUTABLE', {
      url: completeLink,
    });

    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      'create-entity',
    );

    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    handleChange(RichUtils.toggleLink(newEditorState, selection, entityKey));
  };

  const selection = focusedEditor && focusedEditor.editorState.getSelection();
  const blockType = focusedEditor
        && focusedEditor.editorState
          .getCurrentContent()
          .getBlockForKey(selection.getStartKey())
          .getType();
  const currentStyle = focusedEditor && focusedEditor.editorState.getCurrentInlineStyle();

  return (
    <IconsBox>
      <IconsGroup>
        {editorControls.inlineStyles.map(({ key, icon }) => (
          <MarginIconButton
            key={key}
            active={currentStyle && currentStyle.has(key)}
            onMouseDown={(e) => {
              e.preventDefault();
              toggleInlineStyle(key);
            }}
          >
            {icon()}
          </MarginIconButton>
        ))}
      </IconsGroup>
      <IconsGroup>
        <ColorSelector toggleColor={toggleColor} />
      </IconsGroup>

      <IconsGroup>
        <Hyperlink handleAddLink={handleLink} />
      </IconsGroup>

      <IconsGroup>
        {editorControls.blockStyles
          .filter((bs) => bs.type === 'HEADER')
          .map(({ key, icon }) => (
            <MarginIconButton
              key={key}
              onMouseDown={(e) => {
                e.preventDefault();
                toggleBlockType(key);
              }}
              active={blockType === key}
            >
              {icon()}
            </MarginIconButton>
          ))}
      </IconsGroup>
      <IconsGroup>
        {editorControls.blockStyles
          .filter((bs) => bs.type === 'LIST')
          .map(({ key, icon }) => (
            <MarginIconButton
              key={key}
              onMouseDown={(e) => {
                e.preventDefault();
                toggleBlockType(key);
              }}
              active={blockType === key}
            >
              {icon()}
            </MarginIconButton>
          ))}
      </IconsGroup>
    </IconsBox>
  );
};


const IconsGroup = styled.div({
  display: 'flex',

  margin: '0.625rem 0.4063rem',
  [`${devices.laptop}`]: {
    margin: '0.625rem',
  },
});

const IconsBox = styled.div(() => ({
  display: 'flex',
  position: 'fixed',
  background: 'white',
  top: isIOS ? '4.38rem' : 'unset',
  bottom: isIOS ? 'unset' : 0,
  zIndex: 99,
  width: '100%',
  alignItems: 'center',
  [`${devices.laptop}`]: {
    borderTop: 'none',
    width: 'initial',
    justifyContent: 'center',
    display: 'flex',
    top: 'unset',
    bottom: 'unset',
    position: 'relative',
    flexGrow: 1,
    alignItems: 'center',
  },
}));

const MarginIconButton = styled(IconButton)({
  margin: '0 0.125rem',
  [`${devices.laptop}`]: {
    margin: '0 0.3125rem',
  },
});

ToolbarControls.propTypes = {
  focusedEditor: PropTypes.shape({
    editorState: PropTypes.object,
    handleChange: PropTypes.func,
  }).isRequired,
};
export default ToolbarControls;
