import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import Button from '../components/Button';
import Popup from '../components/Popup';
import Text from '../components/Text';

const Container = styled.div({
  border: '1px solid #000000',
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'column',
  padding: '1.25rem',
  height: '13.125rem',
});

const ContainerHeader = styled.div({
  display: 'flex',
  alignContent: 'start',
  justifyContent: 'space-between',
  marginBottom: '0.625rem',
});

const ContainerBody = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  flexGrow: 1,
});

const ContainerFooter = styled.div({
  display: 'flex',
});

const ShareLink = ({ location: { state } }) => {
  const [copiedLink, setCopiedLink] = useState(false);

  const onCopiedLink = () => {
    setCopiedLink(true);
    setTimeout(() => setCopiedLink(false), 4000);
  };

  const popupTitle = state.canvasName ? `share "${state.canvasName}"` : '';

  return (
    <Popup title={popupTitle}>
      <Container>
        <ContainerHeader>
          <Text type="title">Share link</Text>
          <CopyToClipboard text={state.link} onCopy={onCopiedLink}>
            <Button variant={copiedLink ? 'secondary' : 'primary'}>{copiedLink ? 'copied' : 'copy link'}</Button>
          </CopyToClipboard>
        </ContainerHeader>
        <ContainerBody>
          <Text type="body">{state.link}</Text>
        </ContainerBody>
        <ContainerFooter>
          <Text type="body">Anyone with this link has the ability to view and edit this canvas in real-time.</Text>
        </ContainerFooter>
      </Container>
    </Popup>
  );
};

ShareLink.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
};

export default ShareLink;
