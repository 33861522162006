import React from 'react';
import styled from '@emotion/styled';
import Text from './Text';
import { devices } from '../constants/devices';

const prefixIconInputStyle = {
  flex: 1,
  textIndent: '2.5rem',
};

const Icon = styled.i((props) => ({
  position: 'absolute',
  padding: '0.7rem',
  paddingLeft: '1rem',
  fontSize: '1rem',
  lineHeight: 1.875,
  cursor: 'pointer',
  color: props.hasError ? 'red' : 'inherit',
  [`${devices.laptop}`]: {
    fontSize: '1.62rem',
    padding: '1.25rem',
    paddingLeft: '1.25rem',
  },
}));

const Suffix = styled(Text)({
  position: 'absolute',
  top: 'calc(50% - 0.9375rem + 6px)',
  right: '30px',
  [`${devices.tablet}`]: {
    top: 'calc(50% - 0.9375rem)', // line-height * font-size / 2 == height to subtract from middle.
  },
});

const MyInput = styled.input((props) => ({
  fontFamily: 'inherit',
  borderWidth: '1px',
  borderStyle: props.disabled ? 'dashed' : 'solid',
  borderRadius: '62.5rem',
  padding: props.small ? '0 0 0 0.3125rem' : '0.625rem',
  fontSize: props.small ? '0.625rem' : '1rem',
  lineHeight: 1.875,
  ':focus': {
    outline: 'none',
  },
  width: '100%',
  borderColor: props.hasError ? 'red' : 'inherit',
  color: props.hasError ? 'red' : 'inherit',
  '::placeholder': {
    color: props.hasError ? 'red' : 'inherit',
    opacity: '0.5',
  },
  ':disabled': {
    backgroundColor: 'white',
  },

  [`${devices.laptop}`]: {
    padding: props.small ? '0 0 0 0.625rem' : '1rem',
    fontSize: props.small ? '1rem' : '1.62rem',
  },
}));

const InputContainer = styled.div(({ flexGrow }) => ({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  flex: flexGrow ? '1' : 'unset',
}));

const Input = ({
  prefixIcon,
  prefixHandler,
  suffix,
  suffixHandler,
  placeholder,
  error,
  disabled = false,
  small = false,
  flexGrow = false,
  ...otherProps
}) => {
  const inputSuffix = suffix ? (
    <Suffix onClick={suffixHandler}>{suffix}</Suffix>
  ) : null;

  let input = (
    <InputContainer flexGrow={flexGrow}>
      <MyInput
        small={small}
        hasError={error != null}
        placeholder={placeholder}
        disabled={disabled}
        {...otherProps}
      />
      {inputSuffix}
    </InputContainer>
  );

  if (prefixIcon) {
    const className = `fas fa-${prefixIcon}`;
    input = (
      <InputContainer>
        <Icon
          hasError={error != null}
          onClick={prefixHandler}
          className={className}
        />
        <MyInput
          disabled={disabled}
          hasError={error != null}
          small={small}
          placeholder={placeholder}
          {...otherProps}
          style={prefixIconInputStyle}
        />
      </InputContainer>
    );
  }

  return input;
};

export default Input;
