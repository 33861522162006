import React from 'react'
import styled from '@emotion/styled'

import TermsSectionHeader from './TermsSectionHeader'
import TermsSection from './TermsSection'
import Text from './Text'

const TextBody = styled(Text)({
  margin: '0.5rem 0 1.5rem 0 '
})

export default () => (
  <TermsSection>
    <TermsSectionHeader>
      <Text type="display">Digital Millennium Copyright Act (DMCA) Notice</Text>
    </TermsSectionHeader>
    <TextBody type="body">
      If you believe that material available on Simple Canvas App infringes on your
      copyright(s), please notify us by submitting a{' '}
      <a href="#dmca">DMCA notice</a>. After we receive a notice, we will
      investigate, remove the material, and make a good faith attempt to reslove the issue.
    </TextBody>
    <TextBody type="body">
      Before submitting a DMCA notice, it’s important to consider whether the
      material used falls under <strong>fair use</strong>
        .
      If you are not sure whether material located on Simple Canvas App infringes on your
      copyright, or if it is subject to fair use protections, you should first
      consider seeking legal advice.
    </TextBody>
    <TextBody type="body">
      You may be liable for damages (including costs and attorneys’ fees) if you
      materially misrepresent that material or activity infringes on your
      copyright.
    </TextBody>
    <TextBody type="body">
      Your DMCA notice will be forwarded to the party that made the material
      available. In addition, you are required to consider the possible fair use
      implications, as a result of{' '}
      <a href="https://www.eff.org/press/releases/important-win-fair-use-dancing-baby-lawsuit">
        Lenz v. Universal
      </a>
      .
    </TextBody>
    <TextBody type="body">Please follow these steps to file a notice:</TextBody>
    <ol>
      <li>
        <strong>Contact the user directly.</strong> If you are able to make
        contact with the person in question,raise your complaint to see if the
        matter can be resolved directly.
      </li>
      <li>
        <strong>
          Send your complaint to us via{' '}
          <a href="mailto:hello@p22.studio">hello@p22.studio</a>
        </strong>
        , if the issue cannot be resolved directly.
      </li>
    </ol>
    <TextBody type="body">
      As required by the DMCA, we have a policy to terminate users accounts that
      we consider to be repeat infringers. Although we won’t share the specifics
      of our repeat infringer policy (we don’t want anyone to game the system,
      after all), we believe that it strikes the right balance: it protects the
      rights of copyright owners and protects legitimate users from wrongful
      termination. Please note that notices that are successfully countered,
      rejected on fair use grounds, or deemed to be fraudulent are not counted
      against a user.
    </TextBody>
    <TextBody type="body">
      In the email to <a href="mailto:hello@p22.studio">hello@p22.studio</a>{' '}
      please provide the following details:
    </TextBody>
    <TextBody type="body">
      First Name
      <br />
      Last Name
      <br />
      Company Name
      <br />
      Address
      <br />
      City
      <br />
      State/Region/Province
      <br />
      Country
    </TextBody>
    <TextBody type="body">
      Location (URL) of the unauthorized material on Simple Canvas App (NOT simply the
      primary URL of the site - you must provide the full and exact permalink of
      the page where the content appears. We ask you to please submit notices
      for only one page at a time.
    </TextBody>
    <TextBody type="body">
      Please describe the copyrighted work so that it may be easily identified
      <br />
      Location of copyrighted work (where your original material is located, not
      the offending material)
    </TextBody>
  </TermsSection>
)
