import React, { useState } from "react";

import Popup from "../components/Popup";
import Input from "../components/Input";
import Button from "../components/Button";
import ErrorsDisplay from "../components/ErrorsDisplay";
import { changePasswordUserLogged } from "../firebase/index";

import styled from "@emotion/styled";
import * as Yup from "yup";
import { useFormik } from "formik";
import { devices } from "../constants/devices";
import { useHistory, useLocation } from "react-router";

const MarginInput = styled(Input)({
  marginBottom: "0.625rem",
  [`${devices.laptop}`]: {
    marginBottom: "1.25rem",
  },
});

const Validation = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, "new password must have at least 6 characters.")
    .required("enter new password"),
});

const ChangePasswordPage = () => {
  const [errorChangingPassword, setErrorChangingPassword] = useState(null);
  const history = useHistory();
  const location = useLocation();

  const redirectAccountSettings = () => {
    history.push({
      pathname: "/settings",
      state: { background: location.state.background },
    });
  };

  const handleChangePassword = async ({ currentPassword, newPassword }) => {
    try {
      setErrorChangingPassword(null);
      await changePasswordUserLogged(currentPassword, newPassword);
      redirectAccountSettings();
    } catch (e) {
      setErrorChangingPassword(e.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
    },
    onSubmit: handleChangePassword,
    validationSchema: Validation,
  });

  const errors = errorChangingPassword
    ? [...Object.values(formik.errors), errorChangingPassword]
    : Object.values(formik.errors);

  return (
    <Popup title="account settings > change password">
      <ErrorsDisplay errors={errors} />
      <MarginInput
        prefixIcon="arrow-left"
        prefixHandler={history.goBack}
        type="password"
        placeholder={
          formik.errors.currentPassword && formik.touched.currentPassword
            ? formik.errors.currentPassword
            : "current password"
        }
        name="currentPassword"
        value={formik.values.currentPassword}
        onChange={formik.handleChange}
        error={
          formik.touched.currentPassword ? formik.errors.currentPassword : null
        }
      />
      <MarginInput
        type="password"
        placeholder={
          formik.errors.newPassword && formik.touched.newPassword
            ? formik.errors.newPassword
            : "new password"
        }
        name="newPassword"
        value={formik.values.newPassword}
        onChange={formik.handleChange}
        error={formik.touched.newPassword ? formik.errors.newPassword : null}
      />
      <Button type="large" variant="secondary" onClick={formik.handleSubmit}>
        change password
      </Button>
    </Popup>
  );
};

export default ChangePasswordPage;
