import React, { useState } from 'react'
import styled from '@emotion/styled'
import { devices } from '../constants/devices'
import { useLocation, useHistory, } from 'react-router'

const Container = styled.label(({ hasError }) => ({
  display: 'block',
  position: 'relative',
  cursor: 'pointer',
  userSelect: 'none',
  padding: '.625rem',
  paddingLeft: '3rem',
  border: '1px solid',
  borderRadius: '62.5rem',
  borderColor: hasError ? 'red' : 'inherit',
  color: hasError ? 'red' : 'inherit',
  fontSize: '0.9rem',
  lineHeight: 1.875,
  ':focus': {
    outline: 'none',
  },
  width: '100%',
  input: {
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    height: 0,
    width: 0,
  },
  'input:checked ~ span': {
    backgroundColor: 'transparent!important',
  },
  [`${devices.tablet}`]: {
    fontSize: '1rem',
  },

  [`${devices.laptop}`]: {
    fontSize: '1.62rem',
    padding: '1.25rem',
    paddingLeft: '4.375rem',
  },
}))

const Checkmark = styled.span((props) => ({
  position: 'absolute',
  zIndex: 1,
  left: '1.25rem',

  top: 'calc(50% - 0.625rem)',
  height: '1.25rem',
  width: '1.25rem',

  backgroundColor: props.isChecked ? 'transparent' : 'white',
  borderRadius: '100%',
  border: '2px solid #121D32',
  borderColor: props.hasError ? 'red' : 'inherit',

  [`${devices.laptop}`]: {
    top: 'calc(50% - 0.937rem)',
    height: '1.875rem',
    width: '1.875rem',
  },
}))

const CheckmarkChecked = styled.div({
  backgroundColor: '#121D32',
  borderRadius: '100%',
  height: '0.875rem',
  width: '0.875rem',
  top: 'calc(50% - 0.4375rem)',
  left: '1.45rem',
  position: 'absolute',
  [`${devices.laptop}`]: {
    paddingLeft: '1.25rem',
    height: '1.375rem',
    width: '1.375rem',
    top: 'calc(50% - 0.68rem)',
    left: '1.5rem',
  },
})

const ActionSpan = styled.span({
  textDecoration: 'underline',
  cursor: 'pointer',
})

const Checkbox = ({ error, name, onChange }) => {
  const [checked, toggleCheck] = useState(false)
  const handleChange = (event) => {
    toggleCheck(event.target.checked)
    onChange(event)
  }

  const navigateTerms = () => {
    const path = `${document.location.origin}/terms`
    window.open(path, '_blank')
  }

  return (
    <Container hasError={error}>
      accept <ActionSpan onClick={navigateTerms}>terms & conditions</ActionSpan>
      <input
        name={name}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      <Checkmark isChecked={checked} hasError={error}></Checkmark>
      <CheckmarkChecked />
    </Container>
  )
}

export default Checkbox
