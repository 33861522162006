import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Global } from "@emotion/core";

import Box from "../Box";
import useTheme from "../hooks/useTheme";
import { devices } from "../constants/devices";
import { canvasTypes } from "../../variables";
import { isIOS } from 'react-device-detect'
/**
 * Global Draftjs classes. The only way to change them is overriding css
 */
const getThemedGlobalStyles = (color) => ({
  ".public-DraftEditorPlaceholder-inner": {
    color,
    opacity: 0.5,
  },

  ".public-DraftStyleDefault-ul": {
    color,
  },

  ".public-DraftStyleDefault-ol": {
    color,
  },

  ".public-DraftStyleDefault-block": {
    color,
  },
});

const CanvasContainer = styled.div(({ theme, columns, rows }) => ({
  padding: '0.625rem',
  backgroundColor: theme.background,
  display: "grid",
  height: 'auto',
  gridTemplateColumns: "1fr",
  gridAutoRows: "90vh",
  gap: theme.gap,
  [`${devices.laptop}`]: {
    padding: '1.25rem',
    marginTop: 0,
    height: "100%",
    gridTemplateRows: `repeat(${rows}, 1fr)`,
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
  },
}));

const License = styled.div(({background, text}) =>({
  padding: '0.625rem',
  backgroundColor: background,
  '& > *': {
    color: `${text}!important`
  },
  marginBottom: isIOS ? 0 : 50,
  [`${devices.laptop}`]:{
    padding: '1.25rem',
    paddingTop: 0,
    marginBottom: 0,
  }
}));

const getCanvasLicense = (canvasType) => {
  if(canvasType && canvasTypes[canvasType]){
    return canvasTypes[canvasType].license()
  }
}

const ScrollableContent = styled.div({
  display: 'block',
  height: isIOS ? '100vh' : 'auto',
  overflow: isIOS ? 'auto' : 'unset',
  marginTop: isIOS ? '3.125rem' : 0,
})


const Canvas = ({ id, setFocusedEditor, setFocusedEditorRef, canvas, canvasStructure }) => {
  const theme = useTheme(canvas?.theme);

  const canvasContent = () => (
    <>
      <CanvasContainer
      columns={canvasStructure.columns}
      rows={canvasStructure.rows}
      theme={theme}
    >
      {canvasStructure.boxes.map((box) => (
        <Box
          key={box.name}
          rowStart={box.rowStart}
          rowEnd={box.rowEnd}
          columnStart={box.columnStart}
          columnEnd={box.columnEnd}
          theme={theme}
          canvasId={id}
          setFocusedEditor={setFocusedEditor}
          setFocusedEditorRef={setFocusedEditorRef}
          name={box.name}
          placeholder={box.placeholder}
        >
          {box.title}
        </Box>
      ))}
    </CanvasContainer>
    <License background={theme.background} text={theme.text}>
      {getCanvasLicense(canvas.type)}
    </License>
  </>
  )

  if (!canvas) return null;
  return (
    <>
      <Global styles={getThemedGlobalStyles(theme.text)} />
      {isIOS && (<ScrollableContent>
        {canvasContent()}
      </ScrollableContent>)
      }
      { !isIOS && canvasContent()}
    </>
  );
};

export default Canvas;
